.s_sectionxpl{
    padding: 30px;
    background-color: #fff;
}
.xpl_heading{
    padding: 20px;
}
.xpl_heading h1{
    font-weight: 700;
    font-size: 38px;
    line-height: 46px;
    color: #000000;
    margin-bottom: 30px;
}
.xpl_heading p{
    text-align: justify;
    letter-spacing: 0.05em;
    color: rgba(0, 0, 0, 0.75);
    font-weight: 500;
    font-size: 17px;
    line-height: 30px;
    margin-right: 30px;
}
.vediocol{
    padding: 20px;
}
.vediocol iframe{
    height: 380px!important;
}
.about_s_grey{
    padding: 30px;
    background-color: rgba(79, 79, 79, 0.25);
    box-shadow: 2px 4px 15px rgb(0 0 0 / 15%);
}
.about_s_grey h3{
    font-weight: 600;
    font-size: 48px;
    line-height: 43px;
    color: #2F2F2F;
    text-align: center;
    margin-bottom: 30px;
}
.about_s_grey p{
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    text-align: justify;
    letter-spacing: 0.05em;
    color: rgba(0, 0, 0, 0.75);
}
 .page_section{
    padding: 30px;
    
 }
 .histro-box{
    background-color: #fff!important;
    box-shadow: none;
 }
 .inner{
    position: relative;
    height: 300px;
    border: 1px solid #E50013;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
 }
 .inner h5 {
    font-weight: 700;
    font-size: 48px;
    line-height: 36px;
    text-align: center;
    color: #E50013;
}
.inner h6 {
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    color: #000000;
}
.photcontainer {
    padding: 30px;
}
.co_photo{
    text-align: center;
}
.co_photo h5{
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #000000;
    margin-bottom: 20px;
    margin-top: 20px;
}
.co_photo h6{
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #000000;
    margin-bottom: 20px;
}
.bannerimg img{
    width: -webkit-fill-available;
}
@media only screen and (max-width: 992px) {
    .s_sectionxpl{
        padding: 10px;
    }
    .xpl_heading h1 {
        font-size: 28px;
        margin-bottom: 5px;
        text-align: left!important;
    }
    .xpl_heading p{
        margin: 0;
        font-size: 16px;
        line-height: unset;
        margin-bottom: 20px;
    }
    .about_s_grey{
        padding: 10px;
    }
    .about_s_grey h3 {
        font-size: 28px;
    }
    .about_s_grey p {
        font-size: 16px;
    }
}