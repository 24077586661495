@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Maven+Pro:wght@600&display=swap);
@import url(https://fonts.cdnfonts.com/css/dinpro-black);
@import url(https://fonts.googleapis.com/css2?family=Laila:wght@700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #ffffff;
  border-width: 1px !important;
  font-weight: 600;
  color: #ffffff !important;
  background: #d22027 !important;
  transition: box-shadow 0.2s;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #ffffff;
  margin-left: 0.5rem;
}
.MuiCheckbox-root {
  color: rgb(255 255 255 / 54%) !important;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #ffffff !important;
  margin-left: 0.5rem;
}
.MuiSvgIcon-root {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  color: #c8c6c6;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #fff !important;
}
.p-datatable-scrollable-header-box {
  margin-right: 0px !important;
}
.p-column-title {
  font-size: 0.9rem;
  display: block;
}
#zoomA {
  /* (A) OPTIONAL DIMENSIONS */
  width: 600px;
  height: auto;

  /* (B) ANIMATE ZOOM */
  /* ease | ease-in | ease-out | linear */
  transition: -webkit-transform ease-in-out 0.3s;
  transition: transform ease-in-out 0.3s;
  transition: transform ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
}

/* (C) ZOOM ON HOVER */
#zoomA:hover {
  -webkit-transform: scale(4);
          transform: scale(4);
  /* margin-left: 2rem; */
  z-index: 999;
}
.p-datatable .p-datatable-thead > tr > th {
  text-align: center !important;
  padding: 1rem 1rem;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #495057;
}
.p-datatable .p-datatable-tbody > tr > td {
  text-align: center !important;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
}
h4.user {
  padding-left: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0rem !important;
}
.p-datatable table {
  border-collapse: collapse;
  width: 100%;
  table-layout: auto !important;
}

.summary {
  font-size: 14px;
  line-height: 1.5;
}
.summary a {
  color: red !important;
}
.summary a:hover {
  color: blue !important;
  text-decoration: none !important;
}
.summary p.collapse:not(.show) {
  /* height: 15px !important; */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 0rem;
}

.summary p.collapsing {
  min-height: 42px !important;
}

.summary a.collapsed:after {
  content: "+ Read More . . .";
}

.summary a:not(.collapsed):after {
  content: "- Read Less . . .";
}

.p-datatable-wrapper {
  border: 1px solid #ccc;
}

.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid #ccc !important;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
}
.p-datatable .p-datatable-header {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #ccc !important;
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}

.dash .card-header {
  text-align: center !important;
}
.holo-footer {
  width: 100%;
  left: 0px;
  bottom: 0px;
  background-image: url("https://cvitanic.info/wp-content/uploads/2016/05/Footer-background.jpg");
  background-repeat: repeat-x;
  background-color: transparent !important;
  position: fixed;
  bottom: 0;
  line-height: 15px;
}

.holo-footer1 {
  width: 100%;
  left: 0px;
  bottom: 0px;
  background-image: url(/static/media/footer.d51b55a9.jpg);
  background-repeat: repeat-x;
  background-color: transparent !important;
  /* position:fixed;
    bottom:0; */
  line-height: 15px;
}

form {
  background: #fff;
  padding: 1rem;
}

video {
  width: 100vw !important;
  height: 100vh;
  object-fit: cover;
}

/* MENU-UP-DOWN=TRIAGLE */
.p-menubar
  .p-menubar-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link
  > .p-submenu-icon {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link
  > .p-submenu-icon {
  margin-left: auto;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

/* MENU-UP-DOWN=TRIAGLE=END */

/* Haani-group-style */
.login_imge {
  vertical-align: middle;
  border-style: none;
  width: 120%;
  height: 100vh;
}

.box_class {
  width: 100%;
  height: 100%;
  transition-property: width, height;
  transition-duration: 1s;
  transition-timing-function: linear;
}

.box_class:hover {
  width: 150%;
  height: 150%;
}

/* .p-button {
  background: #d77f0c !important;
  border: 1px solid #d77f0c !important;} */

.text_effect {
  text-shadow: 20px 20px 20px bue;
  transition: -webkit-transform 2s;
  transition: transform 2s;
  transition: transform 2s, -webkit-transform 2s;
}

.text_effect:hover {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}

/* .p-menubar .p-menubar-root-d77f0clist > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
  color: #ffffff !important;
  margin-right: 0.5rem;
  font-size: 30px;
} */
:root {
  --primary-color: # !important;
  --blue: #d77f0c !important;
}
img#img01 {
  width: 46%;
  height: 80%;
  top: 10%;
  position: relative;
  left: 27%;
  border-radius: 5px;
  border: 1px solid rgb(0, 0, 0);
}

.flexContainer {
  display: flex;
}

.inputField {
  flex: 1 1;
}

.close {
  position: absolute;
  top: 65px;
  font-size: 30px;
  right: 27%;
  transition: all 200ms;
  width: 2rem;
  height: 2rem;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
  z-index: 9999;
}

div#myModal {
  display: block;
  background-color: rgba(0, 0, 0, 0.4);
}

.sucees-icon {
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgb(0 0 0 / 20%) !important;
}

/* ksk-style */
.MuiOutlinedInput-inputMarginDense {
  padding: 10px !important;
}

.p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link
  .p-menuitem-icon {
  color: #000 !important;
  margin-right: 0.5rem;
}

.p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link
  .p-submenu-icon {
  color: #000 !important;
  margin-left: 0.5rem;
}

.p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link
  .p-menuitem-text {
  color: #000 !important;
}

.p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link
  .p-menuitem-text
  :hover {
  color: #fff !important;
}

.p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link:not(.p-disabled):hover {
  background: #eae9e9 !important;
}

.p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link
  .p-menuitem-icon {
  color: #6c757d;
  margin-right: 1rem !important;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
  padding: 1rem !important;
  margin: 7px 0;
  color: #495057;
  border-radius: 10px !important;
  transition: box-shadow 0.2s;
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
  /* border-bottom: 1px solid #9b9da1; */
}

.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background-color: #eae9e9 !important;
  color: #fff !important;
  border-radius: 5px;
}

.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #000 !important;
}

.header-bg {
  background-color: #fff;
  box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
  /* border-top: 2px solid #000; */
  border-bottom: 2px solid #d22027;
}

.p-menubar .p-submenu-list {
  left: 103%;
  margin-top: -46px;
  padding: 0.25rem !important;
  border-radius: 10px;
}
.p-menubar .p-menuitem-link {
  padding: 0.5rem 1rem;
  color: #495057;
}
a.p-menuitem-link {
  /* border-bottom: 1px solid #ccc; */
}
.p-menubar,
.p-menubar .p-menubar-root-list {
  background: none !important;
  box-shadow: none !important;
}

.logo_bg {
  /* border-top: 2px solid #000; */
  border-bottom: 2px solid #d22027;
  background: #efefef !important;
}

#myHeader li a {
  /* display: block; */
  position: relative;
}

#myHeader li a.arrow::before {
  content: "\f105";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  /*--adjust as necessary--*/
  color: #000;
  font-size: 18px;
  position: absolute;
  top: -4px;
  right: 5px;
}

#myHeader li {
  list-style: none;
}

.sidebar-bg {
  box-shadow: 0 0 3px 0px rgb(0 0 0 / 40%);
  min-height: 90vh;
  background: #fafafa;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.profile-frm input {
  width: 100%;
}

.my-30 {
  margin: 30px 0;
}

.mt-25 {
  margin: 0 25px;
}

.ml-20 {
  margin-left: 20px;
}

.angle span.p-submenu-icon.pi.pi-angle-down {
  display: none;
}

.eye-design {
  padding: 15px 15px;
  color: #3e3d3d;
  background-color: #f7f7f7;
  border: 1px solid #ced4da;
  font-size: 18px !important;
  font-weight: bold !important;
}

.eye-design:hover {
  padding: 15px 15px;
  color: #000;
  background-color: #fff;
  border: 1px solid #ced4da;
  font-size: 18px !important;
  font-weight: bold !important;
}

.slashes {
  padding: 15px 15px;
  color: #3c3592;
  background-color: #e6e6e6;
  border: 1px solid #ced4da;
  font-size: 18px !important;
  font-weight: bold !important;
}
.dash-margin {
  margin-top: 1rem !important;
  margin-bottom: 5rem !important;
}
.btn-lsl {
  background: #000;
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  padding: 10px 22px;
  display: inline-block;
  transition: 0.3s;
}

.btn-lsl:hover {
  background: #d22d26;
  transition: 0.3s;
  color: #fff;
}
h3.mb-0.changepass {
  font-size: 1.5rem;
  text-align: center;
}

/* qr */
.timeline li {
  list-style: none;
  padding-left: 24px;
  text-align: left !important;
  margin-bottom: 20px;
  position: relative;
  /* left: 20px; */
}

.qr .card-header {
  padding: 1.5rem 1.25rem !important;
  text-align: center !important;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.qr .mb-0 {
  margin-bottom: 0 !important;
  font-size: 18px;
}

.timeline {
  position: relative;
  padding: 0;
  counter-reset: section;
  padding-left: 20px;
  transition: all 1.4s;
  overflow-y: hidden;
}
.timeline li:last-child {
  margin-bottom: 0;
}
/* body .p-inputtext {
  padding: 0 !important;
} */
body .p-tabview.p-tabview-top .p-tabview-nav li a {
  padding: 3px 1em !important;
}
body .p-dialog .p-dialog-content {
  background-color: rgb(235, 238, 242) !important;
  color: #495057;
  padding: 0 1.5rem 2rem 1.5rem;
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  padding: 2rem !important;
}
.timeline::before {
  background: green;
  content: "";
  position: absolute;
  left: 17px;
  top: 0;
  width: 2px;
  height: 87%;
}
.timeline:empty {
  height: 0px !important;
  overflow: hidden;
}
.timeline li {
  list-style: none;
  padding-left: 24px;
  margin-bottom: 20px;
  position: relative;
}
.timeline li span {
  color: #949292;
  font-size: 13px;
  font-style: italic;
  display: block;
}
.timeline li::before {
  position: absolute;
  left: -17px;
  top: 0;
  width: 30px;
  height: 30px;
  counter-increment: section;
  content: counter(section);
  background: #48bd0b;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  line-height: 30px;
}

/* .p-datatable .p-datatable-thead > tr > th {
      text-align: center !important};
.p-datatable .p-datatable-tbody > tr > td {
        text-align: center !important; 
      margin: 0 auto;
    display: flex;
  justify-content: center;} */

.form-group.row.dispatch1 {
  margin: 0rem;
}
label.col-lg-4.col-form-label.form-control-label.dispatch1 {
  text-align: right;
}
label.col-lg-3.col-form-label.form-control-label.dispatch1 {
  text-align: right;
}
.col-md-4.text-left.right {
  /* display: flex; */
  text-align: right !important;
  padding-right: 0px;
}
.col-md-2.mapping {
  text-align: right;
}
.row.right {
  display: flex;
  justify-content: end;
  padding-right: 19px;
}

tbody tr:nth-child(odd) {
  background-color: #fff !important;
}
tbody tr:nth-child(even) {
  background-color: #eeeeee !important;
}
tbody tr:hover {
  background-color: red;
}

i.pi.pi-search.searche {
  background: #000;
  padding: 11px;
  margin-left: 0rem;
  font-size: 1.1rem;
  color: #fff;
  border-radius: 3px;
}

/* i.pi.pi-search {
    background: #000;
    padding: 0.7rem;
    margin-left: 0rem;
    font-size: 1.2rem;
    color: #fff;
    border-radius: 3px;
} */
.p-input-icon-left > .p-inputtext {
  padding-left: 2rem !important;
}
button.p-button.p-component.p-button-icon-only {
  background: #ff8b00;
  border: none;
}
button.p-button.p-component.p-datepicker-trigger.p-button-icon-only {
  background: #28a745;
  border: none;
}

button.p-button.p-component.p-button-sm.p-button-icon-only {
  background: #dd1e08;
}
/* 
.p-button {
          color: #ffffff;
          background: #000 !important;
          border: 1px solid #000 !important;
          padding: 0.5rem .5rem !important;}
.p-button:enabled:hover {
            background: #D22D26 !important;
            color: #ffffff;
            border-color: #D22D26 !important;
        }
         */
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #000 !important;
  border-color: none !important;
  color: #ffffff !important;
}
.p-menubar .p-menuitem-link {
  padding: 0.4rem 1rem !important;
}

.col-md-5.product {
  text-align: left;
}
h2.text-center {
  font-size: 1.5rem;
}
.mt10 {
  margin-top: 1rem !important;
}
.mb10 {
  margin-bottom: 5rem !important;
}

.warrantyreg {
  text-align: right;
  background: #e50013 !important;
  padding: 0.5rem !important;
  font-size: 20px !important;
  color: #fff !important;
  border-radius: 5px;
  margin-bottom: 5px;
  text-align: center;
}

.row.warranty {
  text-align: right;
  display: flex;
  justify-content: end;
  margin-right: -5px;
  margin-top: -35px;
}
span.cursor {
  display: flex;
  align-items: center;
  line-height: 19px;
}
.cursor {
  cursor: pointer;
}
.row.socialink {
  margin-left: 10px;
  /* text-align: center; */
}
.col-md-6.col-xl-6.col-xs-12.productename {
  padding-left: 3rem;
}
.socile > .h_mobile {
  display: block !important;
}

@media (max-width: 768px) {
  .productDetils > img {
    width: 60% !important;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    -webkit-transform: translatey(-20px);
            transform: translatey(-20px);
    /* animation: float 6s ease-in-out infinite; */
  }
  .socile > .h_mobile1 {
    display: block !important;
  }
  .col-md-5.success-message.google.success-msg {
    text-align: right;
    margin: 0 0 5px 0px !important;
  }
  h2.success-msg1 {
    font-size: 1.4rem !important;
    margin-bottom: 0rem !important;
    text-align: center !important;
    margin-bottom: 0.5rem !important;
  }
  .row.warranty {
    text-align: right;
    display: flex;
    justify-content: end;
    margin-right: -10px;
    margin-top: 0px;
  }
}
button.p-button.p-component.btn.m-1.danger {
  background: #e21e08;
  color: #ffffff;
  border-color: #e21e08;
  padding: 5px 0px 5px 10px;
}
button.p-button.p-component.btn.m-1.success {
  background: #28a745;
  color: #ffffff;
  border-color: #28a745;
  padding: 5px 0px 5px 10px;
}
button.p-button.p-component.btn.m-1.primary {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  padding: 5px 0px 5px 10px;
}
@media (max-width: 768px) {
  .socile .h_mobile1 {
    display: block !important;
  }
  .socile .left_social {
    position: fixed;
    right: -135px;
    top: 30%;
    z-index: 999;
    display: flex;
    flex-direction: column;
  }
  .rating-wraper {
    margin-left: auto;
    margin-right: auto !important;
    width: 180px;
    background: rgba(255, 255, 255, 1);
    padding: 8px;
    border-radius: 3px;
    box-shadow: 0 5px 4px rgb(0 0 0 / 10%);
  }

  .p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link:not(.p-disabled):hover {
    background: #333333 !important;
  }
  .col-md-6.col-xl-6.col-xs-12.productename {
    padding-left: 1rem;
  }
  .row.socilbox {
    display: flex;
    justify-content: center;
  }
  .row.socialink {
    /* margin-left: 10px; */
    text-align: center !important;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
  .col-md-8.text-right.search {
    text-align: center;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
  .col-md-1.dealar {
    width: 50%;
  }
  .col-md-3.text-right.dealar {
    width: 40%;
  }
  .mt10 {
    margin-top: 0rem !important;
  }
  .mb10 {
    margin-bottom: 7rem !important;
  }
  h2.text-center {
    font-size: 1.2rem;
  }
  span.file-custom {
    /* padding: 2rem; */
    margin: 0px 5rem;
  }
  label.col-lg-4.col-form-label.form-control-label.dispatch1 {
    text-align: left !important;
  }
  .col-md-2.report {
    margin-left: 0.5rem;
  }
  .col-md-3.report {
    margin-left: 0.5rem;
  }
  .col-md-3.text-center.report {
    margin-top: 0.5rem;
  }
  label.col-lg-3.col-form-label.form-control-label.dispatch1 {
    text-align: left;
  }

  .dash-margin {
    margin-top: 0rem !important;
    margin-bottom: 5rem !important;
  }
  .col-md-6.copyright {
    text-align: center !important;
  }

  .col-md-6.text-right.designed {
    text-align: center !important;
  }
  label.col-lg-3.col-form-label.form-control-label {
    text-align: left;
  }
  .datatable-filter-demo {
    margin: 0px 0px 7rem 0px !important;
  }
  h5.main-head {
    padding-left: 10px;
  }
  .col-md-6.text-right.search {
    width: 60%;
  }
  .col-md-2.text-right.add {
    width: 40%;
    text-align: left !important;
  }
  input.p-inputtext.p-component {
    margin: 2px;
    /* padding: 5px; */
  }
  .col-md-1 {
    text-align: center;
  }
  .col-md-3.text-right {
    text-align: center;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
  button.btn.btn-primary.p-2.pl-3.pr-3.mr-2 {
    margin-top: 5px;
  }
  .col-md-6.text-right {
    text-align: center !important;
  }
  /* .p-button {
  color: #ffffff;
  background: #000 !important;
  border: 1px solid #000 !important;
  padding: 0.5rem 0.4rem !important;
} */
  button.p-button.p-component.mr-2 {
    margin-top: 0.3rem;
  }

  .user-area {
    position: relative;
    width: 335px;
    float: right;
    color: #000 !important;
  }
  i.pi.pi-bars {
    font-size: 1.5rem;
    margin-top: 10px;
  }
  img#img01 {
    width: 90% !important;
    height: 50%;
    top: 25%;
    position: relative;
    left: 5%;
    border-radius: 5px;
    border: 1px solid rgb(0, 0, 0);
  }

  .close {
    position: absolute;
    font-size: 30px;
    transition: all 200ms;
    top: 202px;
    right: 3%;
    height: 9rem;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    /* margin-right: 0.5rem; */
    z-index: 9999;
  }

  .logo-wraper {
    position: absolute;
    right: -90px !important;
    width: 180px !important;
    height: 170px !important;
    top: -90px !important;
    border-radius: 100%;
    background: rgba(255, 255, 255, 0.3);
  }

  .logo-wraper .logo-c {
    position: absolute;
    bottom: 5px !important;
    left: 10px !important;
    max-width: 99px;
    right: 80px;
    margin: 0 auto;
    height: 81px;
    text-align: center;
  }

  .sidebar-bg {
    background: #e50013;
    height: 100%;
    min-height: 40px;
  }

  .sidebar-bg {
    margin-top: -40px !important;
  }

  .body-part {
    padding: 15px 0;
  }

  #myHeader .p-menubar-root-list {
    display: block !important;
  }

  .user-area {
    position: relative;
    width: 335px;
    float: right;
    top: -60px !important;
    right: 5px;
  }

  .p-menubar-root-list > .p-menuitem-active > .p-submenu-list {
    width: 100% !important;
    margin-left: 0px !important;
  }

  .co-name {
    margin-left: 5px !important;
    color: #007cba;
    position: relative;
    z-index: 9;
    /* background: #fff; */
    display: table;
    padding: 5px 0 !important;
  }

  .p-calendar {
    position: relative;
    width: 100%;
    display: inline-flex;
  }

  .buttone {
    margin-top: 10px !important;
  }

  .buttone1 {
    padding: 6.5px 14px !important;
  }
}

.logo-head {
  background-color: rgba(0, 0, 0, 0.5) !important;
  padding: 0px !important;
}

.mt-30 {
  margin-top: 7%;
}

.box-shadow {
  box-shadow: 0 4px 17px rgb(0 0 0 / 35%);
}

.p-menuitem-text {
  font-size: 15px !important;
}

.input-group-prepend span {
  width: 50px;
  background-color: #f5f4f4;
  color: #3e3d3d;
  font-size: 20px !important;
  border: 0 !important;
}

input:focus {
  outline: 0 0 0 0 !important;
  box-shadow: 0 0 0 0 !important;
}

.login_btn {
  color: black;
  background-color: #ffc312;
  width: 100px;
}

/* .pi-eye{position: absolute;
  right: 10px;.image-wraper
  top: 20px;} */
.form-group {
  margin: 2rem 0;
}

.fa,
.fas {
  font-weight: 500;
  padding: 8px;
  font-size: 20px;
}

.login-button {
  width: 50%;
  padding: 7px !important;
  font-size: 18px !important;
  border-radius: 10px;
}

@media (min-width: 768px) {
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 73% !important;
  }

  i.pi.pi-bars {
    font-size: 25px;
  }
}

/* responsive-table */

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  text-align: center;
  font-size: 1.1rem;
}

.table .thead-dark th {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #e9ecef;
}

@media (max-width: 991px) {
  .responsive > thead th {
    display: none;
  }

  .responsive > tbody td,
  .responsive > tbody th {
    display: block;
  }

  .responsive > tbody > tr:nth-child(even) td,
  .responsive > tbody > tr:nth-child(even) th {
    background-color: #eee;
  }

  [row-header] {
    position: relative;
    width: 50%;
    vertical-align: middle;
  }

  [row-header]:before {
    content: attr(row-header);
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    width: 50%;
    padding-right: 30px;
  }

  .user-area {
    position: relative;
    width: 335px;
    float: right;
    top: -65px;
    right: 5px;
  }
}

/* responsive-table-end */

/* newstyle */

@media (max-width: 767px) {
  .p-datatable table {
    border-collapse: collapse;
    width: 100% !important;
    table-layout: fixed;
  }

  #myHeader .p-menubar > ul {
    padding: 4px 1% !important;
    z-index: 99999;
  }

  .user-menu {
    display: none;
    position: absolute;
    text-align: left;
    width: 200px;
    color: #272727;
    background: #fff;
    right: -15px !important;
    z-index: 999999999;
    margin-top: 25px !important;
    border-radius: 1px !important;
    overflow: hidden;
    box-shadow: 0 2.8px 2.2px rgb(0 0 0 / 3%), 0 6.7px 5.3px rgb(0 0 0 / 5%),
      0 12.5px 10px rgb(0 0 0 / 6%), 0 22.3px 17.9px rgb(0 0 0 / 7%),
      0 41.8px 33.4px rgb(0 0 0 / 9%), 0 100px 80px rgb(0 0 0 / 12%);
  }
}

/* responsive-style */

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

.download-button {
  font-size: 20px !important;
  font-weight: 600;
  border-bottom: 1px solid #ffa703;
  padding: 0px;
  margin-bottom: 15px;
}

.leftimg {
  position: absolute;
  left: 18px;
  top: 41%;
  width: 20%;
}

.rightimg {
  position: absolute;
  right: 16px;
  width: 20%;
  top: 40%;
}

.copy-right {
  background-color: #ffa703;
  color: #fff;
  text-align: center;
  padding: 15px;
  margin: -10px;
  font-size: 25px;
  border-radius: 40px 40px 0 0;
  font-weight: 600;
}

.logo-c {
  text-align: left;
  margin: -10px 0;
  width: 130px;
}

.social-icon {
  position: absolute;
  right: 1%;
  top: 5px;
}

.social {
  text-align: right;
  margin: 0 25px;
}

.img-fluid {
  max-width: 100%;
  height: auto;
  width: 100%;
}

.divid {
  border-top: 1px solid #ffa703;
  margin-bottom: -12px;
}

.textbox {
  text-align: left;
  font-family: "DINPro-Black", sans-serif;
  font-weight: 600;
  color: #000;
  font-size: 14px;
  line-height: 25px;
}

.textbox1 {
  text-align: left;
  font-family: "Laila", sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 40px;
  color: #000;
  letter-spacing: -2px;
}

.textbox2 {
  text-align: left;
  font-family: "Laila", sans-serif;
  font-weight: bold;
  font-size: 25px;
  line-height: 25px;
  color: #000;
  letter-spacing: -2px;
}

.text-left {
  text-align: left;
  font-family: sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: #000;
  line-height: 25px;
}

.text-left1 {
  text-align: left;
  font-family: "Laila", sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: #000;
  letter-spacing: -2px;
}

.text-left2 {
  text-align: left;
  font-family: "Laila", sans-serif;
  font-weight: bold;
  font-size: 25px;
  line-height: 25px;
  color: #000;
  letter-spacing: -2px;
}

.why-dalmia {
  text-align: center;
  font-family: "Laila", sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: #000;
}

.why-dalmia1 {
  text-align: center;
  font-family: "Laila", sans-serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 25px;
  color: #000;
  padding: 20px 0 0 0;
}

.why-head {
  font-family: "Laila", sans-serif;
  font-weight: bold;
  font-size: 25px;
  line-height: 25px;
  color: #000;
  letter-spacing: -2px;
  padding: 50px 0;
}

.borderbox {
  border: 1px solid #ffa801;
  border-radius: 30px;
  width: 100%;
}

.borderboxes {
  border: 1px solid #ffa801;
  border-radius: 30px;
  width: 100%;
}

.rounded-box {
  display: flex;
  flex-direction: row;
}

.spacing-top {
  margin-top: 3%;
}

.about-me {
  text-align: center;
  font-family: "DINPro-Black", sans-serif;
  font-weight: 600;
  color: #000;
  font-size: 14px;
  line-height: 25px;
  padding: 20px;
}

.center-align {
  width: 80%;
  margin: 0 auto;
}

.center-align1 {
  width: 95%;
  margin: 0 auto;
}

.borderb {
  border-bottom: 1px solid #ffa703;
  padding: 0px 0;
  margin: 15px 0;
}

.borderb1 {
  padding: 0 0 2px 0;
  margin: 10px 0;
}

.about-head {
  text-align: center;
  font-family: "Laila", sans-serif;
  font-weight: bold;
  font-size: 25px;
  line-height: 25px;
  color: #000;
  letter-spacing: -2px;
  padding: 30px 0 0 0;
}

.share {
  padding: 0 8px;
  border-radius: 10px;
  font-weight: bold;
  width: 100%;
  cursor: pointer;
  height: 40px;
}

/* social-icon */

.fa {
  padding: 5px;
  font-size: 18px;
  /* width: 55px; */
  text-align: center;
  text-decoration: none;
  margin: 0px 0px;
  border-radius: 15%;
}

/* 

.fa-facebook {
  background: #eaeaea;
  color: #221f1f;
  border: 1px solid #777;
}

.fa-facebook:hover {
  background: #3b5998;
  color: white;
  text-decoration: none;
}

.fa-twitter {
  background: #eaeaea;
  color: #221f1f;
  border: 1px solid #777;
}

.fa-twitter:hover {
  background: #55acee;
  color: white;
  text-decoration: none;
}

.fa-linkedin {
  background: #eaeaea;
  color: #221f1f;
  border: 1px solid #777;
}

.fa-linkedin:hover {
  background: #007bb5;
  color: white;
  text-decoration: none;
}

.fa-youtube {
  background: #eaeaea;
  color: #221f1f;
  border: 1px solid #777;
}

.fa-youtube:hover {
  background: #bb0000;
  color: white;
  text-decoration: none;
}

.fa-instagram {
  background: #eaeaea;
  color: #221f1f;
  border: 1px solid #777;
}

.fa-instagram:hover {
  background: #125688;
  color: white;
  text-decoration: none;
} */

.popup1 {
  width: 60vw;
}

.popup {
  width: 50vw;
}

.popup3 {
  width: 100%;
  align-items: normal;
  margin: right 23px !important;
  height: 100vh !important;
  max-height: none !important;
}

.popup5 {
  width: 65%;
  align-items: normal;
  margin: right 23px !important;
  height: 56vh !important;
  max-height: none !important;
}

/* .popup3 .ui-dialog-content {
  overflow: hidden !important;
}
.popup3 .ui-dialog-content::-webkit-scrollbar {
  display: none;
} */

.container {
  justify-content: center;
  height: 100% !important;
}

@media (max-width: 767px) {
  .p-dialog .p-dialog-header {
    border-bottom: 0 none;
    background: #ffffff;
    color: #ffffff;
    padding: 1.5rem;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .popup {
    width: 90vw;
  }

  .popup1 {
    width: 90vw;
  }
  .popup5 {
    width: 90vw;
  }
  .box-spacig {
    padding: 0px 0px 0px 0px !important;
  }

  .colomn-spaing {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }

  .about-head {
    text-align: left;
    font-family: "Laila", sans-serif;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    color: #000;
    letter-spacing: -2px;
    padding: 10px 0 20px 0;
  }

  .success-message {
    text-align: center !important;
  }
  .col-md-4.success-message.google {
    text-align: center;
  }
  .order-change {
    display: flex;
    flex-direction: row-reverse;
  }

  .socials {
    text-align: right;
    margin: 0 5px;
  }

  .social-icon {
    position: absolute;
    top: 28px;
    right: -10px;
  }

  .image-wraper {
    border-radius: 5px;
    padding: 0;
    background: #fff;
    position: relative;
    overflow: hidden;
  }

  .leftimg {
    position: absolute;
    left: 11px;
    top: 50%;
    width: 20%;
  }

  .rightimg {
    position: absolute;
    right: 12px;
    width: 20%;
    top: 50%;
  }

  .why-head {
    padding: 10px 0;
    font-size: 18px;
    text-align: center;
  }

  .about-me {
    text-align: left;
    padding: 0px;
    margin: -20px 0 0 0;
  }

  .textbox1 {
    font-size: 15px;
  }

  .textbox2 {
    font-size: 18px;
  }

  .text-left1 {
    font-size: 15px;
  }

  .text-left2 {
    font-size: 18px;
    line-height: 25px;
    color: #000;
    letter-spacing: -2px;
  }

  .copy-right {
    font-size: 18px;
  }

  .share {
    background: #ffb600;
    padding: 0 8px;
    border-radius: 10px;
    color: #fff;
    font-weight: bold;
    width: 100%;
    cursor: pointer;
    height: 40px;
  }

  .fa {
    padding: 7px;
    font-size: 15px;
    /* width: 31px; */
    text-align: center;
    text-decoration: none;
    margin: 5px 2px;
    border-radius: 50%;
  }
}

* {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.box {
  width: 39%;
  background: #fff;
  margin: 50px auto !important;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  border: 4px dashed #ccc;
}

.table_data {
  border: 1px solid #ffa703;
  margin: 10px 0;
  border-radius: 15px;
  padding: 10px;
  text-align: center;
}

.row_data {
  border-bottom: 1px solid #aaa6a6;
  margin-bottom: 20px;
  font-weight: 500;
  text-align: right;
  color: #1a1a1a;
  font-size: 15px;
}

.row_data1 {
  border-bottom: 1px solid #aaa6a6;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-size: 15px;
}
span.poineter {
  cursor: pointer;
}
.feedback_form {
  width: 40vw;
}

@media (max-width: 767px) {
  .row_data {
    border-bottom: none;
    margin-bottom: 0px;
    font-weight: 500;
    line-height: 10px;
    text-align: left;
    margin-left: 20px;
  }

  .row_data1 {
    border-bottom: 1px solid #aaa6a6;
    margin-bottom: 20px;
    color: #1a1a1a;
    text-align: left;
    margin-left: 20px;
  }

  .success-message img {
    line-height: 150px;
    width: 30% !important;
    /* margin-top: 10px; */
  }

  .sucees-icon {
    margin: -20px 0 0 0;
    padding: 10px;
    /* background-color: #fff; */
    text-align: center;
    box-shadow: 0 2px 10px rgb(0 0 0 / 20%) !important;
  }

  .product-detail {
    margin: 0;
  }

  .feedback_form {
    width: 80vw;
  }

  .box {
    width: 80%;
  }
}

::-webkit-scroollbar {
  display: none;
}

body {
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;
  background: #ecf5f8;
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}

body .custom-calender input {
  width: calc(100% - 34px) !important;
}

#myHeader {
  padding-right: 0;
}

/* .co-name img {
  width: 130px;
} */
body .custom-calender {
  display: block !important;
  width: 100% !important;
}

.custom-center {
  text-align: center;
}

.popup-modal {
  width: 50%;
}

.user-area img {
  width: 40px;
  margin-left: 10px;
}

/* Product Authentication page start */
.product-authen {
  background: #fff;
  max-width: 800px;
  margin: 0 auto;
  padding: 12px 20px 0;
}

.qrcodeWraper {
  position: fixed;
  top: 33px;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 800px;
  z-index: 9;
  background: #fff;
  padding: 12px;
  border-radius: 14px;
  text-align: center;
  line-height: normal;
}

.similar-product {
  padding-left: 20px;
}

.similar-product li {
  padding-bottom: 20px;
}

.scan-area > span {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.scan-area > span::after,
.scan-area > span::before {
  position: absolute;
  background: #fff;
  content: "";
}

.qrcodeWraper .top-left-mark::after {
  width: 1px;
  height: 50px;
  left: 56px;
  top: 40px;
}

.qrcodeWraper .top-left-mark::before {
  width: 50px;
  height: 1px;
  left: 56px;
  top: 40px;
}

/* .large-hieght {
    height:30px;
  } */
.MuiFormControl-root {
  width: 100%;
}

.qrcodeWraper .top-right-mark::after {
  width: 1px;
  height: 50px;
  right: 56px;
  top: 40px;
}

.page-list-scroll {
  max-height: 413px;
  overflow-y: auto;
  overflow-x: hidden;
}

.qrcodeWraper .top-right-mark::before {
  width: 50px;
  height: 1px;
  right: 56px;
  top: 40px;
}

.qrcodeWraper .bottom-left-mark::after {
  width: 1px;
  height: 50px;
  left: 56px;
  bottom: 56px;
}

.qrcodeWraper .bottom-left-mark::before {
  width: 50px;
  height: 1px;
  left: 56px;
  bottom: 56px;
}

.qrcodeWraper .bottom-right-mark::before {
  width: 50px;
  height: 1px;
  right: 56px;
  bottom: 56px;
}

.qrcodeWraper .bottom-right-mark::after {
  width: 1px;
  height: 50px;
  right: 56px;
  bottom: 56px;
}

.close-btn {
  position: absolute;
  right: -8px;
  top: -9px;
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  color: #fff;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}

.results {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background: #93ce96;
  z-index: 99;
  text-align: center;
  padding: 4px 12px 5px;
  color: #fff;
}

.results button {
  float: right;
  margin-top: -31px;
  background: none;
  border: none;
  color: #fff;
  font-size: 22px;
}

.results small {
  font-size: 17px;
}

.results strong {
  font-weight: 600;
}

.rating-wraper {
  margin-left: auto;
  margin-right: inherit;
  width: 180px;
  background: rgba(255, 255, 255, 1);
  padding: 8px;
  border-radius: 3px;
  box-shadow: 0 5px 4px rgb(0 0 0 / 10%);
}

.share-wraper {
  display: -webkit-inline-box;
  max-width: 200px;
  background: none;
  background: #fff;
  padding: 3px;
}

.image-wraper .slick-initialized .slick-slide {
  box-shadow: none;
}

.image-wraper .slick-slide img {
  display: block;
  height: 400px;
  max-width: 100%;
  margin: auto;
}

.thanks-mess {
  -webkit-animation: blinker 1.6s linear infinite;
          animation: blinker 1.6s linear infinite;
  font-size: 20px;
  text-align: center;
  padding: 45px 0;
  color: #00549f;
  font-weight: normal;
}

@-webkit-keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.blink-one {
  -webkit-animation: blinker-one 1s linear infinite;
          animation: blinker-one 1s linear infinite;
}

@-webkit-keyframes blinker-one {
  0% {
    opacity: 0;
  }
}

@keyframes blinker-one {
  0% {
    opacity: 0;
  }
}

.blink-two {
  -webkit-animation: blinker-two 1.4s linear infinite;
          animation: blinker-two 1.4s linear infinite;
}

@-webkit-keyframes blinker-two {
  100% {
    opacity: 0;
  }
}

@keyframes blinker-two {
  100% {
    opacity: 0;
  }
}

.product-authen h2 {
  font-size: 22px;
  text-transform: uppercase;
  color: #000;
  font-weight: normal;
}

.image-wraper {
  border-radius: 5px;
  padding: 0;
  background: #fff;
  position: relative;
  overflow: hidden;
}

.logo-wraper .logo-c {
  position: absolute;
  bottom: 30px;
  left: 21px;
  max-width: 99px;
  right: 80px;
  margin: 0 auto;
  height: 81px;
  text-align: center;
}

.logo-c img {
  max-width: 100%;
  height: 100%;
  object-fit: contain;
}

.product-detail span .fa-star {
  color: #eabc04;
}

.product-detail span:last-child {
  cursor: pointer;
}

.notification-bar a {
  padding: 0 10px;
}

.notification-bar {
  text-align: right;
}

.image-wraper > img {
  margin: 0px auto;
  display: table;
}

.product-detail {
  background: rgb(250 250 250);
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  /* margin: -49px 36px 0; */
  position: relative;
  z-index: 9;
  margin-bottom: 10px;
}

.product-detail.product-detail2 {
  margin: 9px 36px 0;
}

.product-detail h3 {
  font-size: 20px;
  font-weight: normal;
  color: #1a1a1a;
  padding-bottom: 6px;
}

.scan-wraper {
  position: fixed;
  top: 10%;
  z-index: 99;
  background: rgba(0, 0, 0, 0.8);
  padding: 22px;
  border-radius: 8px;
  max-width: 500px;
  right: 0;
  margin: 0 auto;
  left: 0;
}

.recommended-wraper {
  margin: 50px 0;
}

.closeBtn {
  position: absolute;
  right: 10px;
  color: #fff;
  top: 5px;
  cursor: pointer;
}

.more-data li {
  list-style: none;
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  font-size: 15px;
}

.more-data {
  margin-top: 38px;
  display: none;
  transition: height 0s;
}

.product-key {
  font-weight: 600;
}

.more-data.showMore {
  display: block;
}

.product-detail span {
  display: inline;
  margin-right: 19px;
  font-size: 15px;
  cursor: pointer;
}

.bottom-bar li:last-child {
  border: none;
}

.bottom-bar ul {
  margin: 0;
}

.bottom-bar li {
  list-style: none;
  display: block;
  text-align: center;
  width: calc(100% / 3);
  padding: 11px 0;
  font-size: 14px;
  float: left;
  border-right: 1px solid #ccc;
  cursor: pointer;
}

.bottom-bar {
  position: fixed;
  bottom: 0;
  max-width: 800px;
  background: rgba(237, 237, 237, 1);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 9;
  color: #333;
}

.close-icon {
  cursor: pointer;
  margin-right: 12px;
}

.install-message a {
  color: #fff;
  border: 1px solid #fff;
  padding: 4px 7px;
  border-radius: 4px;
}

.install-message {
  width: 100%;
  background: #ff6f6f;
  padding: 9px 12px 19px;
  color: #fff;
  margin-bottom: 20px;
}

.product-detail span img {
  margin-right: 4px;
}

.logo-wraper {
  position: absolute;
  right: -75px;
  width: 220px;
  height: 220px;
  top: -100px;
  border-radius: 100%;
  background: rgb(179 175 175 / 30%);
  border: 1px solid #777;
}

.title-wraper h4 {
  font-weight: bold;
  font-size: 20px;
}

.title-wraper a {
  background: #0257a2;
  color: #fff;
  display: inline-block;
  /* float: right; */
  padding: 4px 12px;
  border-radius: 22px;
  font-size: 14px;
  cursor: pointer;
}

.title-wraper {
  margin-bottom: 20px;
}

.info-bar {
  font-size: 15px;
  color: #777777;
  padding: 12px 22px;
  position: absolute;
  bottom: 14px;
  left: 0;
}

.info-bar strong {
  font-weight: 600;
  color: #1a1a1a;
}

.info-bar .fa {
  color: #eabc04;
}

.slick-initialized .slick-slide {
  /* border-radius: 13px; */
  overflow: hidden;
  /* margin: 10px; */
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  height: 100%;
  position: relative;
}

.connection strong {
  font-weight: 600;
}

.connection a {
  font-size: 30px;
  margin-right: 10px;
  color: #0257a2;
}

.product-authen {
  overflow-x: hidden;
}

/* .recommendedItem::after {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    content: '';
    background: rgba(0,0,0,.7);
    z-index: 99;
  } */
.recommendedItem {
  position: fixed;
  top: 32px;
  z-index: 999;
  background: #fff;
  left: 0;
  max-width: 700px;
  right: 0;
  margin: 0 auto;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
  padding: 12px;
}

.recommendedItem li {
  list-style: none;
  display: block;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 18px;
}

.recommendedItem li:last-child {
  border-bottom: none;
}

.recommendedItem .close-btn {
  position: absolute;
  right: -10px;
  top: -12px;
  width: 30px;
  height: 30px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  color: #fff;
  cursor: pointer;
}

.recommendedItem li img {
  width: 120px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  display: inline-block;
  vertical-align: top;
}

.recommendedItem li .content {
  display: inline-block;
  width: 70%;
  float: none;
  min-width: unset;
  padding: 0 12px;
}

.recommendedItem li .content p {
  font-size: 14px;
  margin: 0;
  line-height: normal;
}

.product-authen .recommendedItem .content h2 {
  font-size: 18px;
  margin-bottom: 9px;
  text-transform: unset;
}

.recommendedItem ul {
  overflow-y: auto;
  overflow-x: hidden;
  height: 500px;
}

@media (max-width: 767px) {
  .title-wraper h4 {
    width: 89%;
  }

  .product-authen {
    max-width: 100%;
    overflow-x: hidden;
  }

  .recommendedItem {
    max-width: 90%;
  }
}

@media (max-width: 620px) {
  .product-authen {
    padding: 12px 20px 0;
  }

  .title-wraper h4 {
    width: 74%;
  }

  .more-data li {
    width: 100%;
  }

  .product-authen h2 {
    font-size: 18px;
  }

  .product-detail span.pull-right {
    float: none;
    margin-bottom: 0;
  }

  .product-detail span {
    display: block;
    margin-right: 19px;
    font-size: 15px;
    cursor: pointer;
  }

  .more-data li:nth-child(odd) {
    margin-bottom: 0 !important;
    border-bottom: 0 !important;
    padding-bottom: 2px !important;
  }

  .install-message {
    font-size: 12px;
    margin-bottom: 0;
  }

  .recommended-wraper {
    margin: 50px 0 100px;
  }

  .bottom-bar li .fa {
    display: block;
    font-size: 22px;
    margin-bottom: 6px;
  }

  .product-detail h3 {
    font-size: 17px;
  }
}

@media (max-width: 480px) {
  .mobiletop {
    background: #f2f4f5;
  }

  .p-menubar .p-menubar-button {
    color: #fff !important;
  }

  .video {
    width: 360px !important;
    height: 250px !important;
    margin-left: 23px !important;
  }

  .video11 {
    width: 100% !important;
  }

  .notification-bar a {
    padding: 0 3px;
  }

  .notification-bar a img {
    width: 30px;
  }

  /* .logo-wraper img {
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 72px;
  } */
}

.resp-sharing-button__link,
.resp-sharing-button__icon {
  display: inline-block;
}

.resp-sharing-button__link {
  text-decoration: none;
  color: #fff;
  margin: 0.5em;
}

.resp-sharing-button {
  border-radius: 5px;
  transition: 25ms ease-out;
  padding: 0.5em 0.75em;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

.resp-sharing-button__icon svg {
  width: 1em;
  height: 1em;
  margin-right: 0.4em;
  vertical-align: top;
}

.resp-sharing-button--small svg {
  margin: 0;
  vertical-align: middle;
}

/* Non solid icons get a stroke */
.resp-sharing-button__icon {
  stroke: #fff;
  fill: none;
}

/* Solid icons get a fill */
.resp-sharing-button__icon--solid,
.resp-sharing-button__icon--solidcircle {
  fill: #fff;
  stroke: none;
}

.resp-sharing-button--twitter {
  background-color: #55acee;
}

.resp-sharing-button--twitter:hover {
  background-color: #2795e9;
}

.resp-sharing-button--pinterest {
  background-color: #bd081c;
}

.resp-sharing-button--pinterest:hover {
  background-color: #8c0615;
}

.resp-sharing-button--facebook {
  background-color: #3b5998;
}

.resp-sharing-button--facebook:hover {
  background-color: #2d4373;
}

.resp-sharing-button--tumblr {
  background-color: #35465c;
}

.resp-sharing-button--tumblr:hover {
  background-color: #222d3c;
}

.resp-sharing-button--reddit {
  background-color: #5f99cf;
}

.resp-sharing-button--reddit:hover {
  background-color: #3a80c1;
}

.resp-sharing-button--google {
  background-color: #dd4b39;
}

.resp-sharing-button--google:hover {
  background-color: #c23321;
}

.resp-sharing-button--linkedin {
  background-color: #0077b5;
}

.resp-sharing-button--linkedin:hover {
  background-color: #046293;
}

.resp-sharing-button--email {
  background-color: #777;
}

.resp-sharing-button--email:hover {
  background-color: #5e5e5e;
}

.resp-sharing-button--xing {
  background-color: #1a7576;
}

.resp-sharing-button--xing:hover {
  background-color: #114c4c;
}

.resp-sharing-button--whatsapp {
  background-color: #25d366;
}

.resp-sharing-button--whatsapp:hover {
  background-color: #1da851;
}

.resp-sharing-button--hackernews {
  background-color: #ff6600;
}

.resp-sharing-button--hackernews:hover,
.resp-sharing-button--hackernews:focus {
  background-color: #fb6200;
}

.resp-sharing-button--vk {
  background-color: #507299;
}

.resp-sharing-button--vk:hover {
  background-color: #43648c;
}

.resp-sharing-button--facebook {
  background-color: #3b5998;
  border-color: #3b5998;
}

.resp-sharing-button--facebook:hover,
.resp-sharing-button--facebook:active {
  background-color: #2d4373;
  border-color: #2d4373;
}

.resp-sharing-button--twitter {
  background-color: #55acee;
  border-color: #55acee;
}

.resp-sharing-button--twitter:hover,
.resp-sharing-button--twitter:active {
  background-color: #2795e9;
  border-color: #2795e9;
}

.resp-sharing-button--email {
  background-color: #777777;
  border-color: #777777;
}

.resp-sharing-button--email:hover,
.resp-sharing-button--email:active {
  background-color: #5e5e5e;
  border-color: #5e5e5e;
}

.resp-sharing-button--linkedin {
  background-color: #0077b5;
  border-color: #0077b5;
}

.resp-sharing-button--linkedin:hover,
.resp-sharing-button--linkedin:active {
  background-color: #046293;
  border-color: #046293;
}

.blink_me {
  -webkit-animation: blinker 1s linear infinite;
          animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.rating-wraper .fa {
  font-size: 33px;
  padding: 4px 12px;
}

.rating-wraper .fa-facebook-square {
  color: #385096;
}

.rating-wraper .fa-twitter-square {
  color: #00a7ff;
}

.rating-wraper .fa-youtube-square {
  color: #d40c17;
}

.rating-wraper .fa-google-plus-square {
  color: #dc4b3e;
}

/* Product Authentication page end */

.p-menubar {
  /* background:#e70323!important; */
  /* background: #007cba !important; */
  padding: 0 !important;
  border: none !important;
  border-radius: 0 !important;
  display: block !important;
}

.p-submenu-icon {
  position: absolute;
  right: 8px;
}

.p-submenu-list li {
  border: none !important;
  padding: 0 !important;
  width: 100%;
}

/* .p-button {
  background: #4b4b4b!important;
  border: 1px solid #6c7781!important;
} */
.p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link
  .p-menuitem-text,
.p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link
  .p-menuitem-icon,
.p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link
  .p-submenu-icon {
  color: #495057;
  font-size: 16px !important;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
  box-shadow: none !important;
}

.confirm-icon {
  color: red;
  vertical-align: top;
  margin-right: 8px;
}

.p-menubar-start {
  padding: 4px 0;
}

.alert span {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 4px;
  background: #fff;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  line-height: 21px;
}

.alert {
  position: fixed;
  padding: 12px 52px 12px 1px;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  right: 26px;
  top: 6px;
  z-index: 99;
}

.alert.hide {
  display: none;
}

/* .p-menubar ul li:first-child {
    border-right: 1px solid #ccc;
} */
.p-menubar .p-menuitem {
  position: relative;
  /* border-right: 1px solid #ccc; */
  display: block;
}

.p-menubar-root-list > .p-menuitem-active > .p-submenu-list {
  width: 230px;
  z-index: 9;
  margin-left: 0px;
}

.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-header {
  padding: 5px 4px !important;
}

.datatable-filter-demo {
  margin: 20px 0px 60px 0px;
}

.gridview .selected,
.gridview tbody .selected {
  background-color: #6ccbfb;
  color: #fff;
}

.cards {
  background: #ffffff;
  padding: 1.25rem;
  border: 0 none;
  margin-bottom: 2rem;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.cards h2 {
  color: #495057;
  font-size: 21px;
  margin-bottom: 18px;
}

.login-wraper {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  background-size: cover;
  opacity: 1;
  background-image: url(/static/media/13.c8b6784e.jpg);
}

.customModal {
  position: absolute;
  z-index: 99;
  width: 60%;
  top: 30%;
}

.block {
  margin-top: 22px;
  background: #fff;
  padding: 28px;
  border-radius: 14px;
  border: 4px dashed #f9832a;
}

.block label {
  font-weight: normal;
}

.product-pic {
  width: 80%;
}

.block h2 {
  color: #6c2e2f;
  position: relative;
}

.redeem-form {
  display: table;
  margin: 30px auto 0;
}

.pa-bg {
  /* background: url('./assets/images/bg.jpg') no-repeat;
    background-size: cover; */
  height: 100vh;
  position: fixed;
  width: 100%;
  overflow-y: auto;
}

.block h2::after {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 50px;
  height: 2px;
  background: #f9832a;
  bottom: -7px;
}

.co-name {
  margin: 3px 15px;
  position: relative;
  z-index: 9;
  display: table;
}

#myHeader .p-menubar > ul {
  padding: 12px;
}

#myHeader .p-menubar-root-list {
  display: block !important;
}

.login-container {
  width: 393px;
  margin: 0;
  padding: 0;
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
}

.tree-stucture {
  padding-left: 30px;
  margin-bottom: 8px;
  margin-top: 8px;
  list-style: none;
}

.row1 {
  margin-left: -15px;
  margin-right: -15px;
}

.tree-stucture input {
  margin-right: 12px;
}

.login-logo {
  text-align: center;
  padding: 0;
}

.white-box {
  background: #fff;
  padding: 12px;
}

/* body .p-datatable .p-datatable-tbody > tr > td {
    overflow: auto;
} */
#react-sidebar .sidebar-backdrop.show {
  display: none !important;
}

.border-box {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0 12px;
  margin: 20px 0;
  box-shadow: 0 0 12px #ccc;
  background: #ffffff;
}

label span {
  color: red;
  padding-right: 4px;
}

.profile-frm input {
  margin-bottom: 10px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.user-menu .nav-link a {
  color: #fff;
}

.fullwidth {
  width: 100%;
}

.box {
  background: #fff;
  padding: 12px;
  margin: 30px 0;
  border-radius: 4px;
}

.progress {
  height: 5px;
  margin-bottom: 7px;
}

.progress-bar {
  background-color: #29c352;
}

.box h3 {
  color: #f68430;
}

.box h4 {
  color: #712b2a;
}

.effect3 {
  position: relative;
}

.effect3:before {
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 15px;
  left: 10px;
  width: 70%;
  top: 80%;
  max-width: 300px;
  background: #3a3a3a;
  box-shadow: 0 25px 10px #3a3a3a;
  -webkit-transform: rotate(-10deg);
  transform: rotate(-10deg);
}

.left-bg {
  background: url(/static/media/blue-bg.1fbec52b.jpg) no-repeat;
  background-size: cover;
  color: #fff;
  text-align: center;
  font-size: 19px;
  text-transform: uppercase;
  padding-top: 10%;
}

.left-bg p {
  font-size: 14px;
  color: #ece9e9;
  padding-top: 15px;
}

.form-wraper {
  position: relative;
  padding: 10px 30px;
  text-align: center;
  background: #fafafa;
  /* background: rgb(241 254 255 / 50%); */
  /* background-color: #ffffff55; */
  /* background: #f4faff; */
  box-shadow: -1px 4px 28px 0px rgb(0 0 0 / 8%);
  border-radius: 0;
  max-height: 100vh;
  border-radius: 5px;
}

.p-dialog .p-dialog-header {
  padding: 1rem !important;

  /* border-radius: 10px 10px 0 0 !important; */
  border-bottom: none !important;
  color: #495057;
}

element.style {
}

.pi {
  font-size: 1rem;
}

.pi {
  font-family: "primeicons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.p-component,
.p-component * {
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  /* font-size: 12px; */
}

*,
::after,
::before {
  box-sizing: border-box;
}

*,
::after,
::before {
  box-sizing: border-box;
}

*,
::after,
::before {
  box-sizing: border-box;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 2rem;
  height: 2rem;
  color: #fff !important;
}

.p-dialog .p-dialog-header {
  /* border: 2px solid rgb(4, 144, 238) !important; */
  background: #626060 !important;
  /* background-image: linear-gradient(40deg, #e75121,rgb(255 255 255 / 64%), #e75121)!important; */
  color: #ffff !important;
  padding: 0.8rem !important;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 2rem !important;
  height: 2rem !important;
  color: #ffffff !important;
  border: 0 none;
  border: 1px solid #fff !important;
  background: #e50013 !important;
  border-radius: 20% !important;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
  box-shadow: 0 8px 20px 0 rgb(0 0 0 / 15%);
  -o-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.15);
}

.p-dialog .p-dialog-content {
  background: #c70707;
  color: #495057;
  border: 5px solid #d6d3d3;
  padding: 0 1.5rem 2rem 1.5rem;
  border-top: none;
  border-radius: 0px;
}

.effect4 {
  position: relative;
}

.effect4:after {
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 15px;
  right: 10px;
  left: auto;
  width: 50%;
  top: 80%;
  max-width: 300px;
  background: #3a3a3a;
  box-shadow: 0 25px 10px #3a3a3a;
  -webkit-transform: rotate(10deg);
  transform: rotate(10deg);
}

/* .form-wraper::after {
    background:url(./assets/images/login-bg.jpg) no-repeat;
    background-size:cover;
    z-index: -1;
} */
/* .form-wraper::before {
    background: rgb(59,37,84);
    background: linear-gradient(90deg, rgba(59,37,84,0.7) 0%, rgba(40,54,99,0.7) 46%, rgba(59,37,84,0.7) 100%);
    z-index: 0;
} */
.form-wraper form {
  position: relative;
}

.login-container .heading {
  color: #af215f;
  text-transform: uppercase;
  /* border-bottom: 2px solid #0453a4; */
  display: table;
  width: auto;
  font-weight: 700;
  margin: 30px auto;
  font-size: 25px;
}

.login-container label {
  text-align: left;
  display: block;
  font-weight: normal;
  text-transform: uppercase;
  color: #cacaca;
}

.login-container .form-group {
  position: relative;
}

.login-container .form-group span {
  position: absolute;
  left: 11px;
  top: 4px;
  font-size: 17px;
}

/* .toast-header {
    display: flex;
    align-items: end;
    padding: .25rem .75rem;
    color: #6c757d;
    background-color: transparent;
    background-clip: padding-box;
    border-bottom: none;
    text-align: right;
} */
.form-control {
  font-size: 16px !important;
}

.login-container .form-control {
  color: #000;
  background: rgba(255, 255, 255, 0.4);
  padding: 21px 3px 9px 50px;
  border: none;
  border-bottom: 1px solid #000;
  border-radius: 0;
}

.focus-input100 {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.login-container .form-wraper button[type="submit"]:hover {
  background: #005a87;
}

.focus-input100::before {
  content: "";
  display: block;
  position: absolute;
  top: 31px;
  left: -11px;
  width: 0;
  height: 2px;
  transition: all 0.4s;
}

.focus-input100::after {
  font-family: Material-Design-Iconic-Font;
  font-size: 22px;
  color: #fff;
  content: attr(data-placeholder);
  display: block;
  width: 100%;
  position: absolute;
  top: 6px;
  left: 0px;
  padding-left: 5px;
  transition: all 0.4s;
}

.login-container .form-control:focus {
  box-shadow: none;
}

.login-container .form-control:focus + .focus-input100::before {
  width: 100%;
}

.forgot-pass {
  color: #000000 !important;
  cursor: pointer;
  font-weight: 500;
}

.alert-warning {
  border-left: 3px solid orange;
}

.alert-success {
  border-left: 3px solid green;
}

.alert-danger {
  border-left: 3px solid red;
}

.p-datatable .p-datatable-tbody > tr.success-tr {
  background: #28a745;
  color: #fff;
}

.p-datatable .p-datatable-tbody > tr.warning-tr {
  background: #e0a800;
  color: #fff;
}

/* .pa-outer {
    background:url("./assets/images/login-bg.jpg")no-repeat center center;
    height: 100vh;
    background-size: cover;
    background-attachment: fixed;
} */

.box-center {
  display: contents;
}

.pa-outer > .container {
  background-size: cover;
  position: relative;
  background: #d9d9d9 url(/static/media/blue-bg.1fbec52b.jpg) repeat;
}

.fakecontainer {
  width: 100%;
  background-size: cover;
  position: relative;
  background: #d9d9d9 url(/static/media/blue-bg.1fbec52b.jpg) repeat center center;
}
.col-md-5.success-message.google.success-msg {
  text-align: right;
  margin: 0px 0px 0 0;
}

.success-msg {
  margin: 30px 0px;
}
.success-msg1 {
  text-align: left;
}

.success-message {
  text-align: right;
  /* margin: 15px 0; */
  font-family: "font-family: sans-serif" !important;
  font-size: xx-large;
  /* color: rgb(12, 145, 12); */
  /* text-shadow: 0 0 3px #fffb02, 0 0 5px #ff0000; */
  /* color: rgb(247, 219, 63);
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; */
  /* background-color: #18f09d4b; */
  /* height: 500px; */
}
.fakepage hr {
  margin-top: 0rem;
  margin-bottom: 0rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
h2.success-msg1 {
  font-size: 1.5rem !important;
}
.success-message img {
  width: 25%;
  cursor: pointer;
  margin: 5px 0 0 0;
}
.google img {
  width: 40%;
  margin-right: 14px;
  text-align: right !important;
}
.col-md-4.success-message.google {
  text-align: right;
}
.fake .card-header {
  padding: 0.5rem 1.25rem !important;
  margin-bottom: 0;
}

.p-datatable .p-datatable-tbody > tr.danger-tr {
  background: #c82333;
  color: #fff;
}

.login-container .pi-eye,
.login-container .pi-eye-slash {
  position: absolute;
  right: 17px;
  top: 20px;
}

.login-container .form-control::-webkit-input-placeholder {
  /* Edge */
  color: #313131;
}

.login-container .form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #313131;
}

.login-container .form-control::placeholder {
  color: #313131;
}

.toast {
  background: #ff7d7d;
  color: #fff;
  padding: 12px;
  border-radius: 4px;
  position: absolute;
  top: 9px;
  right: 8px;
}

.login-container .form-wraper button[type="submit"] {
  width: 100%;
  /* border-radius: 22px; */
  background: #007cba;
  border: none;
  padding: 10px;
  margin-bottom: 33px;
  color: #fff;
  text-transform: uppercase;
  margin-top: 30px;
}

.vr-line {
  border-right: 1px solid #ccc;
}

.first-box {
  border-left: 3px solid #64b5f6;
}

.second-box {
  border-left: 3px solid #7986cb;
}

.third-box {
  border-left: 3px solid #4db6ac;
}

.fourth-box {
  border-left: 3px solid #4dd0e1;
}

.cards .fa {
  border-radius: 4px;
  width: 33px;
  height: 30px;
  text-align: center;
  color: #fff;
}

.cards.first-box .fa {
  background: #64b5f6;
}

.cards.second-box .fa {
  background: #7986cb;
}

.cards.third-box .fa {
  background: #4db6ac;
}

.cards.fourth-box .fa {
  background: #4dd0e1;
}

.overview-number {
  font-size: 1.5rem;
  font-weight: 500;
  color: #495057;
}

.cards h3 {
  font-size: 18px;
  color: #ea8508;
}

.overview-text {
  color: #6c757d;
  font-weight: 400;
  margin-top: 0.25rem;
}

.header-top {
  background: #fff;
  color: #797979;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.3);
  /* position: fixed; */
  width: 100%;
  z-index: 9;
  top: 0;
}

.Nav__black {
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 9;
}

.logo-section {
  width: 335px;
  display: inline-block;
  padding: 4px 20px 0;
  vertical-align: top;
}

.logo-section img {
  height: 62px;
}

.user-menu {
  display: none;
  position: absolute;
  text-align: left;
  width: 200px;
  color: #272727;
  background: #efefef;
  right: -12px;
  z-index: 999999999;
  margin-top: 40px;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}

.user-menu .nav-link:hover {
  background: #be3430;
  color: #fff !important;
}
.user-area .dropdown-toggle::after {
  display: inline-block;
  margin-right: 4px;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.right-section {
  display: inline-block;
  width: calc(100% - 335px);
  text-align: right;
  padding: 7px 15px 0 20px;
  margin-bottom: 0;
  position: relative;
  top: 8px;
}

.right-section img {
  width: 40px;
  border-radius: 100%;
  margin: 0 10px;
}
a.desinged-by {
  color: #e50013;
  font-weight: 500;
  /* font-size: large; */
  text-transform: uppercase;
}
.footer {
  /* background: rgb(52 51 51 / 50%); */
  background: #fafafa;
  color: #000;
  padding: 10px 20px 10px 0px;
  font-size: 16px;
  position: fixed;
  bottom: 0;
  border-top: 2px solid #ccc;
  width: 100vw;
  z-index: 8;
}
.tabledata1{
  padding-top: 18px;
}

.designedby a {
  color: #b1d9fb !important;
  margin: 0em;
}
.tabledata{
  padding-left: 10px;
}
.btn233{
  height: 5px;
}
.cotranNo{
  font-size: 13px;
  font-weight: 600;
  color: #000;
}
.Colr11{
  background-color: red;
}
.Colr12{
background-color: green;
}

.PackingBox{
  background-color: #f5f5f5;
  padding: 20px;
  
  border: 2px solid #d5d5d5;
}

.Invoice {
background-color: #fff;
padding: 10px;
}
.Invoice img {
  width: 100%;
  height: 200px;
}
.rounded-circle {
  border-radius: 50% !important;
}

/* You can add global styles to this file, and also import other style files */

.custom-calender .ui-calendar,
.custom-calender .ui-calendar input {
  width: 100%;
}

.custom-calender .ui-calendar input {
  border: 1px solid #ced4da;
}

body .ui-table .ui-table-tbody > tr > td {
  padding: 2px 0.857em;
}

.btn {
  font-size: 13px;
  padding: 0.25rem 0.7rem;
}

body .scroll-td .ui-table .ui-table-tbody > tr > td {
  overflow: hidden !important;
}

.sub_button .btn-primary1 {
  color: #fff;
  /* background-image: linear-gradient( to bottom right, #1211c1, #ff2d37); */
  background-color: #e50013;
  border: 3px double #fff;
  margin: 10px;
}

.sub_button .btn-primary1:hover {
  background: #b60110249;
  color: #ffffff;
  border-color: #f7f7f7;
}

body .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active {
  background-color: #191919 !important;
  color: #f3c500 !important;
}

.sidebars {
  background: #007bff;
  top: 0;
  position: relative;
  height: calc(100vh - 124px);
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 4px 2px !important;
}

.p-datatable-emptymessage {
  text-align: center;
}

.sidebar-wraper {
  width: 19%;
  height: calc(100vh - 106px);
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 71px;
  background: #e70323;
  padding: 0;
  z-index: 9;
}

body #react-sidebar .sidebar-main {
  width: 260px;
  height: calc(100% - 128px);
  background-color: #e70323;
  top: 79px;
  left: 0;
  overflow-y: hidden;
  box-shadow: none;
  z-index: 9;
}

body .sidebar-main-content ul li {
  color: #fff;
}

body .sidebar-main-content ul li:hover {
  background-color: transparent;
  color: #e0e0e0;
  letter-spacing: 2px;
  transition: all 0.3s;
}

body #react-sidebar .sidebar-main.second {
  position: fixed;
  top: 0;
}

body #react-sidebar .second.show {
  top: 0;
}

body .p-panelmenu .p-panelmenu-header > a {
  border: none !important;
  background-color: transparent !important;
  color: #fff !important;
  font-weight: normal !important;
}

body .p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
  color: #fff !important;
  float: right !important;
  position: absolute;
  right: 0;
}

body
  .p-panelmenu
  .p-panelmenu-header:not(.p-highlight)
  > a:hover
  .p-panelmenu-icon {
  color: #fff !important;
}

body .p-panelmenu .p-panelmenu-header.p-highlight > a {
  border: none !important;
  background-color: transparent !important;
  color: #ffffff;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

body .p-panelmenu .p-panelmenu-header > a:focus {
  box-shadow: none !important;
}

.error {
  color: red;
}

.error1 {
  color: red;
  position: absolute;
  top: 50px;
}

.user-menu .nav-link {
  padding: 6px 12px;
  cursor: pointer;
  line-height: 30px;
  border-bottom: 1px solid #c9c4c4;
}

.user-menu .nav-link .fa {
  padding-right: 8px;
}

.ui-panelmenu .ui-menuitem-text {
  padding-left: 12px;
}

.open .sidebar-wraper {
  overflow: visible;
}

body .ui-panelmenu .ui-panelmenu-header.ui-state-active > a {
  border: 1px solid #f9842d !important;
  background-color: #f9842d !important;
  color: #ffffff !important;
}

.custom-mdrop,
.custom-mdrop .ui-multiselect {
  display: block;
  width: 100%;
  margin-bottom: 12px;
}

.table-option .ui-dropdown {
  width: 100%;
  border: 1px solid #ced4da;
}

.open .sidebar-wraper {
  width: 6%;
}

.open .sidebar-wraper .fa {
  font-size: 19px;
}

.open .left-panel {
  display: none;
}

.open .right-panel {
  width: 99%;
  margin-left: 15px;
}

.search-field {
  float: right;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-top: 20px;
  margin-left: 2px;
}

.right-panel {
  margin-bottom: 50px;
  margin-top: 94px;
  padding-right: 30px;
  padding-left: 15px;
  width: 80%;
  display: inline-block;
}

.left-panel {
  width: 20%;
  display: inline-block;
}

.sidebar-wraper a {
  color: #fff;
  padding: 0;
  display: block;
}

.sidebar-wraper a .fa {
  margin-right: 12px;
}

#sidebar-parent {
  padding-top: 12px;
}

body .sidebar-body > ul {
  margin-bottom: 12px;
}

.right-panel.resize {
  max-width: 90%;
  flex: 0 0 90%;
}

.right-panel .heading h1 {
  font-weight: normal;
  font-size: 25px;
  color: #712b2a;
  display: inline-block;
}

.heading {
  display: table;
  width: 100%;
}

.p-dialog {
  margin: right 23px !important;
}

.p-dialog-content label {
  font-weight: normal;
}

body .heading .p-button {
  float: right;
  margin-top: 0;
  margin-bottom: 13px !important;
}

.open .p-panelmenu-header .p-menuitem-text {
  font-size: 0;
}

body .open .p-panelmenu .p-panelmenu-header > a {
  padding: 0.714em 0;
}

.open .p-toggleable-content {
  position: absolute;
  right: -157%;
  margin-top: -30px;
  border-top: 1px solid #ccc;
}

.open .p-toggleable-content-enter-done .p-menuitem-text {
  font-size: 15px;
}

.open .ui-panelmenu .ui-panelmenu-content-wrapper .ui-menuitem-text,
.ui-panelmenu .ui-panelmenu-content-wrapper .ui-menuitem-text {
  font-size: 13px;
}

.open .ui-panelmenu .ui-panelmenu-content-wrapper {
  overflow: hidden;
  position: absolute;
  right: -160%;
  z-index: 999;
}

.custom-drop2 .ui-dropdown {
  width: 100%;
  margin-bottom: 0;
  border: 1px solid #ced4da;
  min-width: 100%;
}

.custom-drop .ui-dropdown {
  width: 100%;
  margin-bottom: 10px;
  border: 1px solid #ced4da;
}

body .ui-panelmenu .ui-panelmenu-header > a {
  border: 1px solid #712b2a !important;
  background-color: #712b2a !important;
  color: #fff !important;
  font-weight: normal;
}

body
  .ui-panelmenu
  .ui-panelmenu-header:not(.ui-state-active)
  > a:hover
  .ui-panelmenu-icon,
body .ui-panelmenu .ui-panelmenu-header > a .ui-panelmenu-icon {
  color: #f5e98e !important;
}

.user-form {
  overflow-x: hidden;
  width: 100%;
  margin: 0;
}

.user-form input[type="checkbox"] {
  margin-right: 9px;
  vertical-align: unset;
}

#left-panel .navbar {
  padding: 0;
}

body .ui-widget {
  font-size: 13px;
}

.table thead th {
  font-weight: 500;
}

#left-panel {
  background: #e4e4e4;
}

#left-panel li {
  display: block;
  width: 100%;
  border-bottom: 1px solid #000;
}

#left-panel li:last-child {
  border: none;
}

#left-panel li a {
  padding: 12px;
  display: block;
  color: #000;
  position: relative;
}

#left-panel li a:hover {
  background: #f8e794;
  text-decoration: none;
}

#left-panel li a i {
  padding-right: 12px;
}

#left-panel li .dropdown-toggle::after {
  right: 10px;
  position: absolute;
  top: 23px;
}

.navbar .navbar-nav li.menu-item-has-children .sub-menu {
  background: #353535;
  border: none;
  box-shadow: none;
  overflow-y: hidden;
  margin: 0;
  border-radius: 0;
  padding-left: 16px;
}

body .navbar .navbar-nav li.menu-item-has-children .sub-menu {
  max-height: 1000px;
  opacity: 1;
  position: static;
}

#left-panel li .sub-menu li {
  border: none;
}

#left-panel li .sub-menu li a {
  padding: 4px 12px;
  color: #fff;
}

.ui-panelmenu .ui-panelmenu-icon {
  float: right;
}

.left-panel .dropdown-toggle::after {
  border: none;
}

.navbar .navbar-nav li.menu-item-has-children > a::before {
  content: "";
  position: absolute;
  top: 23px;
  right: 14px;
  width: 8px;
  height: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: #000 #000 transparent transparent;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: all 0.25s ease;
}

.navbar
  .navbar-nav
  li.menu-item-has-children
  > ul
  > li.menu-item-has-children
  > a::before {
  border-color: #fff #fff transparent transparent !important;
  top: 12px;
}

body .ui-dialog .ui-dialog-titlebar {
  border: 1px solid #1f1f1f !important;
  background: #1f1f1f !important;
  color: #f3c500 !important;
  box-shadow: 0 0 6px #f3c500;
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon:hover {
  color: #d6d6d6 !important;
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon {
  color: #d6d6d6 !important;
}

.add-btn {
  position: absolute;
  right: 0;
  top: 5px;
}

.form-control,
body .ui-inputtext {
  height: auto;
  padding: 5px 8px;
  line-height: normal;
}

.saperator {
  position: relative;
  margin-top: 16px;
  margin-bottom: 20px;
  font-size: 19px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  border-top: 1px solid #ccc;
  padding-top: 10px;
}

.user-form .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #938c55;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(147, 140, 85, 0.25);
}

.navbar .navbar-nav li.menu-item-has-children > a.btn-toggle::before,
.navbar .navbar-nav li.menu-item-has-children > ul > a.btn-toggle::before {
  -webkit-transform: rotate(133deg);
  transform: rotate(133deg);
}

body .ui-paginator {
  padding: 5px 0;
}

.user-area {
  position: relative;
  width: 335px;
  float: right;
  color: #000;
  top: 15px;
  right: 0px;
}

.user-area:hover .user-menu {
  display: block;
}

.right-section .heading,
.heading2 {
  border-bottom: 1px solid #000;
  padding-bottom: 15px;
  font-size: 15px;
  margin-bottom: 12px;
  font-weight: bold;
}

.right-section .heading2 {
  padding-bottom: 0 !important;
}

.user-form h4 {
  text-align: center;
  font-size: 20px;
  margin: 10px 0 20px;
}

.user-form input {
  margin-bottom: 10px;
  font-size: 13px;
}

@media (max-width: 1024px) {
  .btn {
    margin-bottom: 10px;
  }

  .redeem-form .btn {
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  .left-panel {
    display: none;
  }

  .block1 h3 {
    width: 195px;
  }

  #myHeader .p-menubar-root-list {
    display: none !important;
  }

  #myHeader
    .p-menubar.p-component.p-menubar-mobile-active
    .p-menubar-root-list {
    display: block !important;
    background: #201f1f !important;
    top: 40px;
    border-radius: 2px;
  }

  .p-menubar-button i {
    color: #fff;
    margin-top: 15px;
  }

  body .p-menubar .p-menubar-button:hover {
    color: #fff;
    background: transparent;
    outline: none;
    box-shadow: none;
  }

  .p-menubar-root-list > .p-menuitem-active > .p-submenu-list {
    width: 100% !important;
    z-index: 9;
    margin-top: -3px !important;
  }

  .right-panel {
    padding-right: 15px;
    padding-left: 31px;
    width: 100%;
  }

  .user-area {
    width: 100%;
    right: 0px;
  }

  .open .right-panel {
    width: 100%;
  }

  .open .left-panel {
    width: 100%;
    display: block;
  }

  .open .sidebar-wraper {
    width: 100%;
  }

  .open .p-panelmenu-header .p-menuitem-text {
    font-size: 14px;
  }

  .open .p-toggleable-content {
    position: relative;
    right: 0;
    margin-top: 0;
  }

  .sidebar-wraper {
    height: auto;
    overflow: hidden;
    position: relative;
    left: 0;
    top: 79px;
    background: #712b2a;
    padding: 12px;
    z-index: 9;
    padding-left: 36px;
  }

  .popup-modal {
    width: 65%;
  }
}

@media (max-width: 580px) {
  .popup-modal {
    width: 95%;
  }
  .success-msg1 {
    text-align: right !important;
  }
  .success-msg {
    margin: 0px 0px;
  }
  .box {
    margin: 10px 0;
  }

  .redeem-form .btn {
    margin: 0 auto;
  }

  .redeem-form .form-group {
    width: 100%;
  }

  .right-panel .heading h1 {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .h1,
  h1 {
    font-size: 20px;
    text-align: center;
    /* line-height: 1px; */
  }

  .product-pic {
    width: 100%;
  }

  .row1 {
    margin-left: 0;
    margin-right: 0;
  }

  .logo-section {
    width: 100%;
    text-align: center;
  }

  .right-section {
    width: 100%;
    text-align: center;
    padding: 0px 15px 0 20px;
  }

  .right-panel {
    margin-top: 18px;
  }

  .header-top {
    position: relative;
    left: 0;
  }

  .sidebar-wraper {
    top: 3px;
  }

  .right-panel {
    padding-right: 23px;
    padding-left: 23px;
  }
}

.tblAuto .p-datatable-wrapper > table {
  border-collapse: collapse !important;
  width: 100% !important;
  table-layout: auto !important;
}

.tblAuto .p-datatable-thead > tr > th:last-child {
  width: 120px !important;
}

body .p-paginator .p-dropdown .p-dropdown-label,
body .p-paginator .p-dropdown .p-dropdown-trigger {
  padding-bottom: 1px !important;
  color: #848484 !important;
}

audio {
  width: 220px !important;
  height: 54px;
}

.Demo__some-network {
  margin: 0 5px !important;
}

.table-data {
  border: 1px solid #ccc;
  border-radius: 15px;
  padding: 20px 0 0 315px;
}

.video1 {
  width: 550px !important;
  height: 350px !important;
}

.video1 {
  width: 100% !important;
  height: auto !important;
  margin-right: -15px;
}

.row-data {
  width: 50% !important;
  border-bottom: 1px solid #ccc !important;
  text-align: center !important;
}

.heading {
  font-size: 23px;
  line-height: 18px;
}

/*input#number {
  padding: 5px;
  text-align: center;
}

 div#increase {
  font-size: 25px;
  display: contents;
}

div#decrease {
  font-size: 25px;
  position: absolute;
  left: 5px;
} */

/* div#pr_id_6 {
  padding: 5px !important;
  border-radius: 5px;
  border: 1px solid #b4b5b7;
  background: #f2f2f2;
} */

.mapping label {
  margin-top: 7px;
  padding-left: 10px;
}

/* PA-style */
.fakebtn .btn {
  font-size: 20px !important;
}

.blink-text {
  color: #000;
  font-weight: bold;
  font-size: 2rem;
  -webkit-animation: blinkingText 2s infinite;
          animation: blinkingText 2s infinite;
}
@-webkit-keyframes blinkingText {
  0% {
    color: #10c018;
  }
  25% {
    color: #1056c0;
  }
  50% {
    color: #ef0a1a;
  }
  75% {
    color: #254878;
  }
  100% {
    color: #04a1d5;
  }
}
@keyframes blinkingText {
  0% {
    color: #10c018;
  }
  25% {
    color: #1056c0;
  }
  50% {
    color: #ef0a1a;
  }
  75% {
    color: #254878;
  }
  100% {
    color: #04a1d5;
  }
}
.desingede a {
  color: #ffffff !important;
  font-size: 15px !important;
}
.copy-righttext {
  padding: 5px 0 !important;
  color: #fff;
}
.copy-righttext .col-md-6 {
  font-size: 15px;
}
.pa-contacte .fa {
  padding: 5px;
  font-size: 25px;
  width: 30px;
}
.wapperbgColor {
  /* background:#ffff; */

  /* background-image: url("./assets/images/lsl.jpg");

  height: 100vh !important; */
  /* height: 100% !important; */
}
.AuthPageTopbox {
  background-color: #fff;
  /* background-image: linear-gradient(60deg, rgba(255, 166, 0, 0.39),rgba(255, 0, 0, 0.26)); */
  background-color: #e50013;
  height: 71px;
  /* border-bottom: 2px solid #E50013; */
}

.AuthPageTop {
  background-color: #e50013;
  width: 100%;
  /* height: 70px; */
}
.AuthPageTop h4 {
  color: #fff;
  text-align: right;
  line-height: 50px;
  margin-right: 11%;
}
.AuthPageTop h4 span {
  font-size: 16px;
  margin-left: 2%;
}
.AuthPageTopimg {
  position: relative;
  bottom: 5px;
  margin-left: 9%;
}
.Authfooter {
  background-color: #d40c17;
  bottom: 0;
  margin-bottom: 0px !important;
  padding-bottom: 0px;
  width: 100%;
  position: absolute;
  bottom: 0;
  position: fixed;
  bottom: 0;
}
.col-12.col-md-12.footerimg.text-center.thanku {
  margin-bottom: 3rem;
}
.AuthproductRow {
  border-right: 1px solid #007bb5;
}

.authProdutname {
  font-weight: 500;
}

.auth_blockproduct {
  width: 80%;
  background-color: rgb(255, 255, 255);
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  margin-bottom: 20px;
}

.authTableProduct p {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
  font-size: 14px !important;
}

.authTableProduct {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.authTableProduct td,
.authTableProduct th {
  border: 1px solid #e21e08;
  padding: 5px;
}

.authTableProduct tr:nth-child(even) {
  background-color: #e21e08 !important;
}

.authTableProduct tr:hover {
  background-color: rgba(191, 252, 209, 0.397);
}

.authTableProduct th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04aa6d;
  color: white;
}
.AuthPageTopimg img {
  width: 55%;
}
.success-message1 img {
  width: 140%;
  cursor: pointer;
  margin: 5px 0 0 0;
}
.p-mt-2 {
  text-align: center;
  margin: 1rem;
}
.card-body.text-center.fakebtn {
  margin-bottom: 2rem;
}
.Productinfo0.card {
  margin-bottom: 5rem;
}
button.p-button.p-component.scratchcode {
  background: #28a745;
  color: #ffffff;
  border-color: #28a745;
}
.row.socilbox {
  margin-bottom: 1rem;
}
button.p-button.p-component.verified {
  background: #28a745;
  color: #ffffff;
  border-color: #28a745;
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #000000 !important;
}
.col-md-3.report {
  display: flex;
  align-items: center;
}
.col-md-2.report {
  display: flex;
  align-items: center;
}
label.col-md-4.col-form-label.form-control-label.dispatch1 {
  text-align: right;
}
.col-md-6.wr {
  display: flex;
  align-items: center;
  justify-content: end;
}
.col-md-3.googleplays {
  display: flex;
  align-items: center;
  justify-content: right !important;
  text-align: right !important;
  margin-bottom: 2rem;
}
.pa-contacte {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  /* margin-top: 1.5rem !important; */
}
.papapage h4 {
  font-size: 1.4rem;
}
/* Mobile */
@media (max-width: 600px) {
  .pa-contacte {
    display: flex;
    align-items: left;
    justify-content: center !important;
    margin-top: -5rem !important;
  }
  .papapage h4 {
    font-size: 12px;
    text-align: left;
    display: inline;
    justify-content: center;
    margin-left: 0px;
  }
  .col-md-3.googleplays {
    display: flex;
    align-items: center;
    justify-content: right !important;
    text-align: right !important;
    margin-top: -3rem;
  }
  .col-md-6.contactdetail {
    padding-right: 0px !important;
    padding-left: 0px !important;
    text-align: center;
    margin-top: 0rem !important;
  }

  label.col-md-4.col-form-label.form-control-label.dispatch1 {
    text-align: left;
  }
  h4.user {
    padding-left: 1rem;
    margin-top: 0rem;
    margin-bottom: 0.5rem !important;
  }
  .warrantyreg {
    text-align: right;
    background: #e50013 !important;
    padding: 0.1rem !important;
    line-height: 7px;
    font-size: 0px !important;
    color: #fff !important;
    border-radius: 5px;
    margin-bottom: 5px;
    text-align: center;
    /* line-height: 1; */
  }
  .Productinfo0.card {
    margin-bottom: 7rem !important;
  }
  .success-message1 img {
    width: 30%;
    cursor: pointer;
    margin: 5px 0 0 0;
  }
  .AuthPageTopimg {
    position: relative;
    bottom: 58px;
    margin-left: 1%;
  }
  .authTableProduct {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100% !important;
  }
  .thanku img {
    margin-bottom: 3rem !important;
  }
  .Authfooter {
    background-color: #d40c17;
    bottom: 0;
    margin-bottom: 0px !important;
    padding-bottom: -700px;
    width: 100%;
    position: fixed;
    bottom: 0;
  }
  .AuthPageTopimg img {
    width: 30%;
    display: flex;
    justify-content: start;
  }
  .AuthPageTopimg {
    position: relative;
    bottom: -35px;
    margin-left: 1% !important;
  }
  .AuthPageTop h4 {
    color: #fff;
    text-align: left;
    line-height: 0px;
    margin-right: 0% !important;
    font-size: 13px;
    /* padding-right: 25px; */
    width: 150vw;
    text-align: center;
  }
  .AuthPageTopbox {
    background-color: #fff;
    background: #e50013;
    height: 92px;
    border-bottom: 2px solid #e50013;
  }
  .pa-contacte .fa {
    padding: 1px;
    font-size: 16px;
    width: 30px;
  }
  .AuthPageTop {
    background-color: #e50013;
    width: 100%;
    height: 30px;
    text-align: center;
    margin: 0 auto;
    display: flex;
  }

  .pa-outer > .container {
    width: 90%;
    background-size: cover;
    position: relative;
  }

  .product-detail {
    background: rgb(250 250 250);
    border-radius: 5px !important;
    padding: 5px;
  }

  .auth_blockproduct {
    width: 90%;
    background-color: rgb(255, 255, 255);
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    margin-bottom: 20px;
  }
}

.product-auth-img-box {
  height: 200px;
}

.timeline {
  position: relative;
  padding: 0;
  counter-reset: section;
  padding-left: 20px;
  transition: all 1.4s;
}

.timeline li:last-child {
  margin-bottom: 0px !important;
}

.timeline::before {
  background: green;
  content: "";
  position: absolute;
  left: 17px;
  top: 0;
  width: 2px;
  height: 87%;
}

.timeline:empty {
  height: 0px !important;
  overflow: hidden;
}

.timeline li {
  list-style: none;
  padding-left: 24px;
  margin-bottom: 10px;
  position: relative;
}

.timeline li div {
  list-style: none;
  padding-left: 5px;
  margin-bottom: -5px;
  position: relative;
}

.timeline li span {
  color: #949292;
  font-size: 13px;
  font-style: italic;
  display: block;
}

.timeline li::before {
  position: absolute;
  left: -17px;
  top: 0;
  width: 30px;
  height: 30px;
  counter-increment: section;
  content: counter(section);
  background: #48bd0b;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  line-height: 30px;
}

.last-half {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
}

.card-info {
  margin-left: 20px;
  text-align: left;
  width: 100px;
  height: 100%;
  position: relative;
}

.card-info > h2 {
  font-weight: 700;
  font-size: 19px;
  font-family: "Lexend Deca", sans-serif;
  color: var(--color-secondary);
  height: 32%;
  line-height: 2rem;
  overflow: hidden;
  margin: auto;
  margin-top: 0.1rem;
}

.card-info > h4 {
  font-weight: 400;
  font-size: 13px;
  font-family: "Lexend Deca", sans-serif;
  color: var(--color-tertiary);
  line-height: 1.5rem;
  margin: auto;
}

.card-info > h6 {
  font-weight: 400;
  font-size: 11px;
  font-family: "Lexend Deca", sans-serif;
  color: var(--color-tertiary);
  line-height: 1.5rem;
  margin: auto;
}

.card-info > h5 {
  font-family: "Lexend Deca", sans-serif;
  color: var(--color-tertiary);
  line-height: 2rem;
  margin: auto;
}

form {
  margin: 0 auto;
  text-align: center;
}
.configuraton form {
  margin: inherit !important;
  text-align: center;
}
.value-button {
  display: inline-block;
  border: 1px solid #2d4059;
  margin: 0px;
  width: 40px;
  height: 40px;
  text-align: center;
  vertical-align: middle;
  padding: 2px 0;
  background: #2d4059;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.value-button:hover {
  cursor: pointer;
}

form #decrease {
  margin-bottom: 7px;
  margin-right: -4px;
  border-radius: 8px 0 0 8px;
  font-size: 20px;
  color: #06457c;
}

form #increase {
  margin-bottom: 7px;
  margin-left: -4px;
  border-radius: 0 8px 8px 0;
  font-size: 20px;
  color: #06457c;
}

form #input-wrap {
  margin: 0px;
  padding: 0px;
}

input#number {
  text-align: center;
  font-size: 16px;
  border: none;
  border-top: 1px solid #2d4059;
  border-bottom: 1px solid #2d4059;
  margin: 4px;
  width: 55px;
  height: 40px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.row-center {
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  display: flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
}

.agile_top_brands_grids {
  margin-top: 40px;
}

/* Shine */
.hover14 figure {
  position: relative;
}

.hover14 figure::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.hover14 figure:hover::before,
.top_brand_left:hover .hover14 figure::before {
  -webkit-animation: shine 0.75s;
  animation: shine 0.75s;
}

.hover14.column {
  border: 1px solid #c0bebe;
}

.agile_top_brand_left_grid1 img {
  max-width: 100% !important;
}

.agile_top_brand_left_grid_pos {
  position: absolute;
  top: 0%;
  right: 0%;
}

.agile_top_brand_left_grid1 {
  padding: 1em;
}

.agile_top_brand_left_grid1 img {
  margin: 0 auto;
}

.top-brands {
  background: #f5f5f5;
  padding: 5em 0;
}

.agile_top_brand_left_grid1 p {
  color: #212121;
  margin: 1.5em 0 1em;
  line-height: 1.5em;
  text-transform: capitalize;
  font-size: 14px;
  text-align: center;
}

.agile_top_brand_left_grid1 h4 span,
.agileinfo_single_right_snipcart h4 span {
  font-weight: 300;
  text-decoration: line-through;
  padding-left: 1em;
}

.agile_top_brand_left_grid1 img {
  max-width: 100% !important;
}

.agile_top_brand_left_grid1 p {
  font-size: 13px;
}

.snipcart-thumb a {
  display: block;
  text-align: center;
}

.stars {
  text-align: center;
  margin-bottom: 13px;
}

.snipcart-details {
  text-align: center;
  margin: 1.5em auto 1em;
  width: 77%;
}

.snipcart-details input.button {
  font-size: 14px;
  color: #fff;
  background: #3399cc;
  text-decoration: none;
  position: relative;
  border: none;
  border-radius: 0;
  width: 100%;
  text-transform: uppercase;
  padding: 0.5em 0;
  outline: none;
}

.agile_top_brand_left_grid:hover .snipcart-details input.button,
.snipcart-details input.button:hover {
  background: #fe9126;
}

.snipcart-details {
  width: 81%;
}

.snipcart-details {
  width: 100%;
}

.snipcart-details input.button {
  width: 54%;
}

.block1 {
  background: #fff;
  padding: 15px;
  width: 100%;
  height: 360px;
  border-radius: 14px;
  border: 2px solid #06457c;
}

.block1 label {
  font-weight: normal;
}

.block1 h4 {
  color: #000000;
  margin-top: 5px;
  height: 40px;
  position: relative;
  justify-content: center;
}

.fa-cart-plus {
  background: #2d4059;
}

.addtocart {
  border-radius: 100px;
  border: none;
  background: #2d4059;
  cursor: pointer;
  transition: -webkit-transform 0.1s;
  transition: transform 0.1s;
  transition: transform 0.1s, -webkit-transform 0.1s;
  z-index: 1;
  color: #fff;
  height: 40px !important;
  width: 150px;
  align-items: center;
  margin-top: 7px;
  justify-content: center;
  top: 3;
}

.addtocart:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.addtocart .fa {
  width: auto;
  margin: 0px 0px;
}

/* .pretext {
  color: #fff;
  background: #2d4059;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
} */
/* .done {
  color: #fff;
  background: #06457c;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  transition: transform 0.3s ease;
  transform: translate(-110%) skew(-40deg);
}
.done {
  color: #fff;
  background: #06457c;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  transition: transform 0.3s ease;
  transform: translate(-110%) skew(-40deg);
  justify-content: center;
} */
.posttext {
  background: #06457c;
}

.fa-check {
  background: #06457c;
}

.rowButton {
  left: auto;
  right: auto;
}

.custom-select {
  background-color: whitesmoke !important;
  height: 35px;
}

.sendOtpbtn button {
  width: 100px;
  height: 30px;
  background-color: #473fab;
  color: rgb(255, 255, 255);
  border: 3px double #fff;
  float: right;
  margin-top: -20px;
  margin-bottom: 5px;
  border-radius: 10px;
}

.forgetclass {
  float: left;
  margin-left: 20px;
}

.fileUpload {
  float: left;
}

.blockimgprive {
  background-color: #639bb928;
}

.dataTables_wrapper {
  /* font-family: "Calibri"; */
  font-size: 12px !important;
  position: relative;
  clear: both;
  letter-spacing: 0.8px;
  /* *zoom: 1; */
  zoom: 1;
}

.addMore {
  border: none;
  width: 32px;
  height: 32px;
  transition: all ease-in-out 0.2s;
  cursor: pointer;
}

.addMore:hover {
  border: 1px solid #888;
  background-color: #ddd;
}

.pa-block2 {
  padding: 15px;
  max-height: 250px;
  border-radius: 14px;
  margin: 20px !important;
}

.pa-block h3 {
  color: #000000;
  margin-top: 20px;
  position: relative;
  justify-content: center;
}

.first_td {
  text-align: left;
  padding: 5px;
  font-weight: 600;
  width: 20%;
}

.p-rating .p-rating-icon {
  margin-left: 0px !important;
  display: inline !important;
  margin: 8px !important;
}

.share-span {
  margin: 2px !important;
}

.fa-5x {
  margin-left: auto;
  margin-right: auto;
  font-size: 3.5rem;
  color: #06457c;
  padding-right: 1rem;
}

.sliderIframe {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
}

iframe {
  width: 100%;
  height: auto;
}

.textaline {
  line-height: 80px;
}

.centered {
  margin-top: 50px;
  margin-left: -80px;
}

@media (max-width: 620px) {
  .centered {
    margin-top: 1px !important;
    margin-left: 1px !important;
  }
}

.slick-slider {
  width: 100% !important;
  height: 70% !important;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.slider-height {
  height: 600px;
}

@media (max-width: 1400px) {
  .slider-height {
    height: 535px !important;
  }
}

.file-custom:before {
  position: absolute;
  top: -0.075rem;
  right: -0.075rem;
  bottom: -0.075rem;
  z-index: 6;
  display: block;
  content: "Browse";
  height: 2.5rem;
  padding: 0.5rem 1rem;
  line-height: 1.5;
  color: #555;
  background-color: #eee;
  border: 0.075rem solid #ddd;
  border-radius: 0 0.25rem 0.25rem 0;
}

.file-custom:after {
  content: attr(data-content);
}

.file {
  position: relative;
  display: inline-block;
  cursor: pointer;
  height: 2.5rem;
}

.file input {
  min-width: 30rem;
  margin: 0;
  filter: alpha(opacity=0);
  opacity: 0;
}

.file-custom {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  line-height: 1.5;
  color: #555;
  background-color: #fff;
  border: 0.075rem solid #ddd;
  border-radius: 0.25rem;
  box-shadow: inset 0 0.2rem 0.4rem rgb(0 0 0 / 5%);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.centerAlign {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

rect {
  fill: #2d4050 !important;
  /* rx: 15;
  ry: 15; */
}

rect:hover {
  fill: #06457c !important;
}

.dx-c-bs4-stroke-current-color {
  stroke: none !important;
}

.css-12vmndj.Target-root {
  stroke: none !important;
}

.css-Header {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-weight: 600 !important;
  font-size: 1.5rem !important;
}

.css-legend span {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}

.svg-bar svg {
  fill: #2d4050;
}

.span-status {
  align-items: center;
  border-radius: 12px;
  color: rgb(255, 255, 255);
  cursor: default;
  display: inline-flex;
  flex-grow: 0;
  flex-shrink: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 0.9rem;
  line-height: 2;
  font-weight: 600;
  justify-content: center;
  letter-spacing: 0.5px;
  min-width: 20px;
  padding-left: 8px;
  padding-right: 8px;
  text-transform: uppercase;
  white-space: nowrap;
}

.pending {
  background-color: rgb(209, 67, 67);
}

.p-dispatch {
  background-color: rgb(255, 176, 32);
}

.dispatch {
  background-color: rgb(20, 184, 166);
}

.table-head {
  display: table-header-group;
  background-color: rgb(243, 244, 246);
  border-bottom: none;
}

.legend-item span {
  width: 150px !important;
}
@media (max-width: 620px) {
  .pie-chart {
    margin-top: 10px !important;
  }

  .legend-item span {
    width: 55px !important;
    font-size: 10px !important;
  }
}

@media (max-width: 480px) {
  .pie-chart {
    margin-top: 10px !important;
  }

  .legend-item span {
    width: 100px !important;
    font-size: 10px !important;
  }
  #customers td {
    font-weight: 400;
    border: 1px solid #ddd;
    padding: 3px;
    font-size: 12px;
  }
}

@media (max-width: 1400px) {
  .h4,
  h4 {
    font-size: 1.2rem;
  }

  .h5,
  h5 {
    font-size: 1.15rem;
  }
}

.GstCertificate img {
  width: 100%;
  height: 300px;
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td {
  border: 1px solid #ddd;
  padding: 3px;
}

.tdnamesize {
  width: 40%;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

.scrollable-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

/* Style for the top section */
.top-section {
  flex-grow: 1;
  overflow-y: auto;
}

/* Style for the bottom section */
.bottom-section {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
}

.orderbooking {
  height: 86vh;
}

@media (max-width: 1367px) {
  .orderbooking {
    height: 87vh;
  }
}

.span-status {
  align-items: center;
  border-radius: 12px;
  color: rgb(255, 255, 255);
  cursor: default;
  display: inline-flex;
  flex-grow: 0;
  flex-shrink: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 0.9rem;
  line-height: 2;
  font-weight: 600;
  justify-content: center;
  letter-spacing: 0.5px;
  min-width: 20px;
  padding-left: 8px;
  padding-right: 8px;
  text-transform: uppercase;
  white-space: nowrap;
}

.full {
  background-color: rgba(255, 8, 8, 0.733);
}

.Danger {
  background-color: rgba(255, 8, 8, 0.733);
}

.Warning {
  background-color: rgba(226, 159, 14, 0.726);
}

.NonDanger {
  background-color: rgba(40, 207, 18, 0.726);
}

.empty {
  background-color: rgba(40, 207, 18, 0.726);
}

input[type="date"] {
  background-color: #0080ff;
  padding: 8px;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  top: 70%;
  left: 45%;
  font-family: "Roboto Mono", monospace;
  color: #ffffff;
  font-size: 10px;
  border: none;
  outline: none;
  border-radius: 5px;
}

::-webkit-calendar-picker-indicator {
  background-color: #ffffff;
  padding: 5px;
  cursor: pointer;
  border-radius: 3px;
}

.jodit-status-bar {
  display: none !important;
}

.papageimg {
  width: 180px;
  height: 180px;
}

.Productinfo {
  /* background-color: rgba(255, 255, 255, 0.068); */
  border-radius: 10px;
  border: 1px solid #e50013 !important;
}
.Productinfo0 {
  border-radius: 10px;
  border: 1px solid #e50013 !important;
  padding: 5px;
  /* background-color: rgba(255, 17, 0, 0.034); */

  /* background-image: linear-gradient(120deg, rgba(255, 17, 0, 0.034), rgba(255, 251, 0, 0.096)); */
}
.productDetils {
  /* background-color: yellow;   */
  /* border-right: 1px solid #E50013 !important; 
  border-bottom: 1px solid #E50013 !important; 
  border-bottom-right-radius: 20px;  */
}
.productDetils > img {
  margin-top: 20px;
  width: 80%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  -webkit-transform: translatey(-20px);
          transform: translatey(-20px);
  /* animation: float 6s ease-in-out infinite; */
}

@-webkit-keyframes float {
  0% {
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }

  50% {
    -webkit-transform: translatey(-20px);
            transform: translatey(-20px);
  }

  100% {
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
}

@keyframes float {
  0% {
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }

  50% {
    -webkit-transform: translatey(-20px);
            transform: translatey(-20px);
  }

  100% {
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
}
.contactdetail {
  color: white !important;
  margin-top: 1.1rem;
  font-size: 1.4rem !important;
}

.newsletter-box {
  padding: 20px;
}
.productDetils h4 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  letter-spacing: 0.5px;
  color: Red;
  /* text-shadow: -1px 0 yellow, 0 1px black, 1px 0 black, 0 -1px black; */
}
.rightfooter {
  color: #ffff;
  margin-left: 100px;
}
.lefttfooter {
  color: #ffff;
  margin-left: 10px;
}
.GiftGenrate{
  background-color: #fff;
  height: 84vh;
  padding: 5px;
}
.ddlClass{
  width:70%;
}

.tableheadering{
  width:100%;
}
.tdtable{
  width: 50%;
  padding-right: 10px;
}
/* new product packing */
.y_product{
  font-size: 13px;
  font-weight: 600;
  color: #000;
} 
.p_item_qty {
  font-size: 15px;
  font-weight: 600;
  color: #D22027;
  padding-left: 3px;
}
.W_card {
  display: flex !important;
  justify-content: center !important;
  padding: 10px !important;
  text-align: center !important;
  color: #fff;
}
.W_card_row {
  background: #d40c17;
}
.p_name_col {
  background: #b5b5b5;
  color: fff;
  color: #000;
  padding: 5px;

  display: flex;
  justify-content: center;
}
.p_name_col p {
  font-size: 1.2rem;
  font-weight: 600;
}
.wa_table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.wa_table td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.social_row_wa {
  display: flex;
  justify-content: center;
  text-align: center;
}
._waproductimg {
  width: 250px;
}
/* .datatable-filter-demo {
  margin: 0px !important;
} */
.wa_AuthPageTopbox {
  background-color: #e50013;
  height: 74px;
}
@media (max-width: 600px) {
  ._waproductimg {
    width: 190px;
  }
  .wa_AuthPageTopbox {
    height: 104px;
  }
}


.container-fluid.loginbg {
  padding-left: 0px;
  }
.dash-card .secondary-bg {
  background-color: #f5b4b9;
}

.dash-card  .rounded-full {
  border-radius: 100%;
}
.p-3.bg-white.shadow-sm.d-flex.justify-content-around.align-items-center.rounded {
  border-radius: 10px !important;
}

.dash-card #menu-toggle {
  cursor: pointer;
}

.dash-card .list-group-item {
  border: none;
  padding: 20px 30px;
}

.dash-card .list-group-item.active {
  background-color: transparent;
  color: var(--main-text-color);
  font-weight: bold;
  border: none;
}
.card-header.dashchart{
  color: #fff;
    /* background-color: #2d4050; */
     background: linear-gradient(to bottom, rgba(255,255,255,0.15) 0%, rgba(0,0,0,0.15) 100%), radial-gradient(at top center, rgba(255,255,255,0.40) 0%, rgb(255 0 0 / 40%) 120%) #d22027;
  background-blend-mode: multiply,multiply;
    /* border-color: #3e3797; */
    font-size: 18px;
    text-align: center;
 
}

canvas.chartjs-render-monitor {
  height: 220px !important;
}
.holos img {
  width: 110px;
}
/* Animated */
.button{
  text-decoration:none;
  font-size:20px;
  background: rgb(30,22,163);
/* background: linear-gradient(180deg, rgba(30,22,163,1) 0%, rgba(4,103,89,1) 0%, rgba(0,212,255,1) 100%); */
  /* background: linear-gradient(to right, #e75021 0%, #fd9d08 100%); */
  /* background: linear-gradient(90deg, #0072ff 0%, #00d4ff 100%); */
  background: linear-gradient(90deg, #E50013 0%, #97797c 100%);
 color: #fff;
  padding:20px 50px;
  border:3px solid #E50013;
  position:relative;
}
.button::before,
.button::after{
  content:" ";
  position:absolute;
  width:40px;
  height:40px;
  border:inherit;
  transition:all .5s;
}
.button::before{
  top:-15px;
  left:-15px;
  border-width:3px 0 0 3px;
}
.button::after{
  bottom:-15px;
  right:-15px;
  border-width:0 3px 3px 0;
}
.button:hover::before,
.button:hover::after{ 
  width:calc(100% + 27px);
  height:calc(100% + 27px);
}
.bg-layer {
  /* background: rgb(141 173 175 / 50%); */
  min-height: 100vh;
}
.login-logo img {
  width: 50%;
}
img.img-fluid.liginbg {
  height: 100vh;
}
.mt5 {
  margin-top: 5rem;
}
/* transation-style */
.head_data {
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 1px 0 1px 0;
  padding: 0.5rem;
  font-weight: 500;
}

.box-space .form-group {
  margin: 0rem 0 !important;
}

.heading {
  font-size: 20px !important;
  line-height: 10px !important;
}

.p-dropdown .p-dropdown-label {
  background: transparent;
  border: 0 none;
  padding: 2px !important;
}

.p-breadcrumb {
  background: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  padding: 0.5rem !important;
}

.leftspace {
  padding-left: 10px !important;
}

.scan_item {
  padding-left: 9% !important;
}

.p-card .p-card-content {
  padding: 0 !important;
}

.p-card .p-card-body {
  padding: 0 !important;
}

.bordercolor {
  border: 0.15rem solid transparent;
  border: 2px solid #dee2e6 !important;
  border-image-slice: 1;
  padding: 1rem;
}

.billing-add {
  font-weight: 600 !important;
  font-size: 1.25rem;
}


.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 2rem;
  height: 2rem;
  color: #fff !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: #fff !important;
  border-color: transparent;
  background: #e9ecef;
}

.bordere {
  border: 0.15rem solid transparent;
  border: 2px solid #dee2e6 !important;
  border-image-slice: 1;
  padding: 1rem;
  margin-top: 1.5rem;
}

.p-dialog .p-dialog-content {
  background: whitesmoke !important;
  color: #495057;
  border: 2px solid #ffffff;
  padding: 1.5rem !important;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

@media (min-width: 576px) {
  .form-inline .form-control {
    display: inline-flex !important;
    width: auto;
    vertical-align: middle;
  }
}

.textle {
  text-align: left;
}

.boxspabottunspacece {
  padding: 20px;
}

.bottunspace {
  margin-bottom: 20px;
}

.boxspace {
  padding: 20px;
}

.placeholders {
  margin-bottom: 30px;
  text-align: center;
}

.placeholders h4 {
  margin-bottom: 0;
  font-size: 36px;
}

.panel-info {
  padding-bottom: 10px;
  /* border: 2px solid #3c3593; */
}

.panel {
  border-radius: 0px;
  margin-bottom: 0px;
  background-color: #fff;
}
.no-gutters {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.panel-body {
  margin-top: 10px;
}

.bar-card {
  border: none;
  border-radius: 5px;
}

.panel-info .panel-heading {
  border-radius: 0px;
  color: #fff;
  /* background-color: #2d4050; */
  background: linear-gradient(to bottom, rgba(255,255,255,0.15) 0%, rgba(0,0,0,0.15) 100%), radial-gradient(at top center, rgba(255,255,255,0.40) 0%, rgb(255 0 0 / 40%) 120%) #d22027;
  background-blend-mode: multiply,multiply;
  /* border-color: #3e3797; */
 font-size: 16px;
    text-align: center;

}

.panel-heading {
  padding: 5px 5px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
}

.panel-success {
  padding-bottom: 10px;
  /* border: 2px solid #3c3593; */
}

.panel-success > .panel-heading {
  border-radius: 0px;
  color: #fff;
  background-color: #2d4050;
  /* background-image: linear-gradient(to right, #3c3592, #5147c9); */
  border-color: #3e3797;
  font-size: 18px;
}

.panel-warning {
  padding-bottom: 10px;
  /* border: 2px solid #3c3593; */
}

.panel-warning > .panel-heading {
  border-radius: 0px;
  color: #fff;
  background-color: #2d4050;
  /* background-image: linear-gradient(to right, #3c3592, #5147c9); */
  border-color: #3e3797;
  font-size: 18px;
}

.panel-danger {
  padding-bottom: 10px;
  /* border: 2px solid #3c3593; */
}

.panel-danger > .panel-heading {
  border-radius: 0px;
  color: #fff;
  background-color: #2d4050;
  /* background-image: linear-gradient(to right, #3c3592, #5147c9); */
  border-color: #3e3797;
  font-size: 18px;
}

.panel-primary {
  padding-bottom: 10px;
  /* border: 2px solid #3c3593; */
}

.panel-primary > .panel-heading {
  border-radius: 0px;
  color: #fff;
  background-color: #2d4050;
  /* background-image: linear-gradient(to right, #3c3592, #5147c9); */
  border-color: #3e3797;
  font-size: 18px;
}

.boxx .cards {
  border: none;
  border-radius: 0px;
  box-shadow: none;
  /* box-shadow: 0 2px 10px rgb(0 0 0 / 10%) !important; */
}

.bux {
  /* box-shadow: 0 2px 10px rgb(0 0 0 / 10%) !important;
  background: #fff; */
  justify-content: space-around;
}

.bux .btn {
  padding: 0.2rem 0.1rem !important;
}
.col-6.col-sm-2.service-card {
  border-right: 2px solid rgba(0,0,0,0.15) ;
}
.margin-y {
  margin: 1rem 0 !important;
}
.fa-4x {
  border-radius: 50%;
  font-size: 1.8rem !important;
  color: #E50013;
}
.display-4 {
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.2;
  text-align: left;
}

.text-uppercase {
  text-transform: uppercase !important;
  text-align: left;
}

.service-card {
  text-align: center;
  /* box-shadow: 0 2px 2px rgb(0 0 0 / 20%); */
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 0.1);
}

.service-card:hover {
  box-shadow: 4px 4px 4px rgb(58 105 243 / 30%);
  -webkit-transform: translate(0, -6px);
          transform: translate(0, -6px);
  box-shadow: 0 0px 0px rgb(0 0 0 / 20%);
}

.m-top {
  margin: 2rem 0;
}

/* responsiveness */
@media screen and (min-width: 679px) and (max-width: 1320px) {
  .btn-success {
    margin: 0 0px !important;
    padding: -10px !important;
    font-size: 12px !important;
  }

  .btn-primary {
    margin: 0 0px !important;
    padding: -10px !important;
    font-size: 12px !important;
  }
}

.cross-button {
  display: grid;
  align-items: center;
  background: #f2f2f2;
  margin: 11px 0 0 0;
  padding: 3px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

@media (max-width: 768px) {
  .boxspace {
    padding: 0 20px;
}
.bottunspace {
  margin-bottom: 0px;
}
  .me-4 {
    margin-right: 10rem!important;
}
.form-wraper {
  position: relative;
  padding: 10px 2rem 0 0;
}
.flex-grow-1.me-2 {
  text-align: center;}
  .mt5 {
    margin-top: 0rem;
  }
  img.img-fluid.liginbg {
    height: 200px;
}
.container-fluid.loginbg {
  padding-left: 0px;
  padding-right: 0px;
}
  .dash-card .fa {
    padding: 7px;
    font-size: 15px;
    width: 55px !important;}
    .col-md-4.card-d {
      border-bottom: 2px solid #f5b4b9;
  }
  .cross-button {
    margin: 11px 0 0 15px;
  }

  .panel-heading {
    padding: 10px 8px;
  }

  .panel {
    margin: 10px;
  }

  .m-top {
    margin: 0rem 0 4rem 0;
  }

  .m-button {
    margin-bottom: -2rem 0 5rem;
  }

  
}

.user-area:hover .user-menu {
  display: inline-block;
}

@media (max-width: 768px) {
  .user-area:hover .user-menu {
    display: block;
  }
}


.display-4 {
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.2;
  text-align: left;
}

.text-uppercase {
  text-transform: uppercase !important;
  text-align: left;
}

.service-card {
  text-align: center;
  /* box-shadow: 0 2px 2px rgb(0 0 0 / 20%); */
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 0.1);
}

.service-card:hover {
  box-shadow: 4px 4px 4px rgb(58 105 243 / 30%);
  -webkit-transform: translate(0, -6px);
          transform: translate(0, -6px);
  box-shadow: 0 0px 0px rgb(0 0 0 / 20%);
}

.m-top {
  margin: 0 0 5rem 0;
}
 
/* dash-style */
.dash li::after {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  right: -1.5em;
  width: 0;
  height: 0;
  border-top: 2.5em solid #EEE;
  border-right: 1.563em solid transparent;
}
.dash li::before {
  content: '';
  position: absolute;
  display: block;
  left: -1.5em;
  width: 0;
  height: 0;
  border-top: 2.5em solid transparent;
  border-right: 1.563em solid #EEE;
}
.headerbg{
  background-color: #fafafa;
  margin-top: 25px;}
.dash .card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: .5rem !important;
}
.dash .card-header {
  padding: 0.9rem !important;
  margin-bottom: 0;
  /* background-color: #fdf2e8; */
  border-bottom: 1px solid rgba(0,0,0,.125);
}
.big-box{
  background: #fbfbfd;
  padding: 10px;
  border-radius: 10px;
  /* margin: 0 5px; */
  box-shadow: -1px 4px 28px 0px rgb(0 0 0 / 8%);
}
.small-box
 {background: #fbfbfd;
  padding: 10px;
  border-radius: 10px;
  box-shadow: -1px 4px 28px 0px rgb(0 0 0 / 8%);
}
.box-data{text-align: center;
  padding: 0px 0 0 0;
  border: 1px solid #c8c8c8;
  border-radius: 10px;
  background: #fff;
  margin: px 0;}
.count-no{
  margin: 10px 0 0 0 !important;
  font-size: 2rem;
}
.text-head{    
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  color: #000;}
  .text-head1 {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    color: #E50013;
    background: #fdf2e8;
}
  .Target-root.css-4dretl {
    height: 400px !important;
  
}

.css-legend span {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
    text-transform: lowercase;
}

/* responsiveness */
@media screen and (min-width: 679px) and (max-width: 1320px) {
  .btn-success {
    margin: 0 0px !important;
    padding: -10px !important;
    font-size: 12px !important;
  }

  .btn-primary {
    margin: 0 0px !important;
    padding: -10px !important;
    font-size: 12px !important;
  }
}

.cross-button {
  display: grid;
  align-items: center;
  background: #f2f2f2;
  margin: 11px 0 0 0;
  padding: 3px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

@media (max-width: 768px) {
  .Target-root.css-4dretl {
    height: 330px !important;
  
}
  .cross-button {
    margin: 11px 0 0 15px;
  }

  .panel-heading {
    padding: 10px 8px;
  }
  .panel-info .panel-heading {
    border-radius: 0px;
    color: #fff;
    /* background-color: #2d4050; */
    /* background: linear-gradient(to bottom, rgba(255,255,255,0.15) 0%, rgba(0,0,0,0.15) 100%), radial-gradient(at top center, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.40) 120%) #989898;
    background-blend-mode: multiply,multiply;
    border-color: #3e3797; */
    font-size: 15px;
}

  .panel {
    margin: 10px;
  }

  .m-top {
    margin: -2rem 0 5rem 0;
  }

  .m-button {
    margin-bottom: -2rem 0 5rem;
  }

  .service-card {
    text-align: center;
    /* box-shadow: 0 2px 2px rgb(0 0 0 / 20%); */
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 0.1);
  }

  .p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link
    .p-menuitem-text,
  .p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link
    .p-menuitem-icon,
  .p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link
    .p-submenu-icon {
    color: #fff !important;
    font-size: 14px;
  }

  p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link:not(.p-disabled):hover {
    background: #01454a;
  }
}

.user-area:hover .user-menu {
  display: inline-block;
}

@media (max-width: 768px) {
  .user-area:hover .user-menu {
    display: block;
  }
  .holos img {
    width: 125px !important;
}
}
.symbol.symbol-40px .symbol-label {
  width: 50px;
  height: 50px;
}
span.text-muted.fw-semibold.d-block.fs-7 {
  font-size: 1.8rem;
}
span.text-gray-800.text-hover-primary.fs-6.fw-bold {
  font-size: 1rem;
}
.symbol .symbol-label {
  width: 50px;
  height: 50px;
}
.symbol .symbol-label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: var(--bs-symbol-label-color);
  background-color: var(--bs-symbol-label-bg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 0.475rem;
}
.symbol {
  display: inline-block;
  flex-shrink: 0;
  position: relative;
  border-radius: 0.475rem;
}
.me-4 {
  margin-right: 1rem;
}

div {
  display: block;
}
.separator {
  display: block;
  height: 0;
  border-bottom: 1px solid #dbdfe9;
}
.text-icon {
  color: #fff !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

.s_sectionxpl{
    padding: 30px;
    background-color: #fff;
}
.xpl_heading{
    padding: 20px;
}
.xpl_heading h1{
    font-weight: 700;
    font-size: 38px;
    line-height: 46px;
    color: #000000;
    margin-bottom: 30px;
}
.xpl_heading p{
    text-align: justify;
    letter-spacing: 0.05em;
    color: rgba(0, 0, 0, 0.75);
    font-weight: 500;
    font-size: 17px;
    line-height: 30px;
    margin-right: 30px;
}
.vediocol{
    padding: 20px;
}
.vediocol iframe{
    height: 380px!important;
}
.about_s_grey{
    padding: 30px;
    background-color: rgba(79, 79, 79, 0.25);
    box-shadow: 2px 4px 15px rgb(0 0 0 / 15%);
}
.about_s_grey h3{
    font-weight: 600;
    font-size: 48px;
    line-height: 43px;
    color: #2F2F2F;
    text-align: center;
    margin-bottom: 30px;
}
.about_s_grey p{
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    text-align: justify;
    letter-spacing: 0.05em;
    color: rgba(0, 0, 0, 0.75);
}
 .page_section{
    padding: 30px;
    
 }
 .histro-box{
    background-color: #fff!important;
    box-shadow: none;
 }
 .inner{
    position: relative;
    height: 300px;
    border: 1px solid #E50013;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
 }
 .inner h5 {
    font-weight: 700;
    font-size: 48px;
    line-height: 36px;
    text-align: center;
    color: #E50013;
}
.inner h6 {
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    color: #000000;
}
.photcontainer {
    padding: 30px;
}
.co_photo{
    text-align: center;
}
.co_photo h5{
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #000000;
    margin-bottom: 20px;
    margin-top: 20px;
}
.co_photo h6{
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #000000;
    margin-bottom: 20px;
}
.bannerimg img{
    width: -webkit-fill-available;
}
@media only screen and (max-width: 992px) {
    .s_sectionxpl{
        padding: 10px;
    }
    .xpl_heading h1 {
        font-size: 28px;
        margin-bottom: 5px;
        text-align: left!important;
    }
    .xpl_heading p{
        margin: 0;
        font-size: 16px;
        line-height: unset;
        margin-bottom: 20px;
    }
    .about_s_grey{
        padding: 10px;
    }
    .about_s_grey h3 {
        font-size: 28px;
    }
    .about_s_grey p {
        font-size: 16px;
    }
}
