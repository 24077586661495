
.container-fluid.loginbg {
  padding-left: 0px;
  }
.dash-card .secondary-bg {
  background-color: #f5b4b9;
}

.dash-card  .rounded-full {
  border-radius: 100%;
}
.p-3.bg-white.shadow-sm.d-flex.justify-content-around.align-items-center.rounded {
  border-radius: 10px !important;
}

.dash-card #menu-toggle {
  cursor: pointer;
}

.dash-card .list-group-item {
  border: none;
  padding: 20px 30px;
}

.dash-card .list-group-item.active {
  background-color: transparent;
  color: var(--main-text-color);
  font-weight: bold;
  border: none;
}
.card-header.dashchart{
  color: #fff;
    /* background-color: #2d4050; */
     background: linear-gradient(to bottom, rgba(255,255,255,0.15) 0%, rgba(0,0,0,0.15) 100%), radial-gradient(at top center, rgba(255,255,255,0.40) 0%, rgb(255 0 0 / 40%) 120%) #d22027;
  background-blend-mode: multiply,multiply;
    /* border-color: #3e3797; */
    font-size: 18px;
    text-align: center;
 
}

canvas.chartjs-render-monitor {
  height: 220px !important;
}
.holos img {
  width: 110px;
}
/* Animated */
.button{
  text-decoration:none;
  font-size:20px;
  background: rgb(30,22,163);
/* background: linear-gradient(180deg, rgba(30,22,163,1) 0%, rgba(4,103,89,1) 0%, rgba(0,212,255,1) 100%); */
  /* background: linear-gradient(to right, #e75021 0%, #fd9d08 100%); */
  /* background: linear-gradient(90deg, #0072ff 0%, #00d4ff 100%); */
  background: linear-gradient(90deg, #E50013 0%, #97797c 100%);
 color: #fff;
  padding:20px 50px;
  border:3px solid #E50013;
  position:relative;
}
.button::before,
.button::after{
  content:" ";
  position:absolute;
  width:40px;
  height:40px;
  border:inherit;
  transition:all .5s;
}
.button::before{
  top:-15px;
  left:-15px;
  border-width:3px 0 0 3px;
}
.button::after{
  bottom:-15px;
  right:-15px;
  border-width:0 3px 3px 0;
}
.button:hover::before,
.button:hover::after{ 
  width:calc(100% + 27px);
  height:calc(100% + 27px);
}
.bg-layer {
  /* background: rgb(141 173 175 / 50%); */
  min-height: 100vh;
}
.login-logo img {
  width: 50%;
}
img.img-fluid.liginbg {
  height: 100vh;
}
.mt5 {
  margin-top: 5rem;
}
/* transation-style */
.head_data {
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 1px 0 1px 0;
  padding: 0.5rem;
  font-weight: 500;
}

.box-space .form-group {
  margin: 0rem 0 !important;
}

.heading {
  font-size: 20px !important;
  line-height: 10px !important;
}

.p-dropdown .p-dropdown-label {
  background: transparent;
  border: 0 none;
  padding: 2px !important;
}

.p-breadcrumb {
  background: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  padding: 0.5rem !important;
}

.leftspace {
  padding-left: 10px !important;
}

.scan_item {
  padding-left: 9% !important;
}

.p-card .p-card-content {
  padding: 0 !important;
}

.p-card .p-card-body {
  padding: 0 !important;
}

.bordercolor {
  border: 0.15rem solid transparent;
  border: 2px solid #dee2e6 !important;
  border-image-slice: 1;
  padding: 1rem;
}

.billing-add {
  font-weight: 600 !important;
  font-size: 1.25rem;
}


.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 2rem;
  height: 2rem;
  color: #fff !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: #fff !important;
  border-color: transparent;
  background: #e9ecef;
}

.bordere {
  border: 0.15rem solid transparent;
  border: 2px solid #dee2e6 !important;
  border-image-slice: 1;
  padding: 1rem;
  margin-top: 1.5rem;
}

.p-dialog .p-dialog-content {
  background: whitesmoke !important;
  color: #495057;
  border: 2px solid #ffffff;
  padding: 1.5rem !important;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

@media (min-width: 576px) {
  .form-inline .form-control {
    display: inline-flex !important;
    width: auto;
    vertical-align: middle;
  }
}

.textle {
  text-align: left;
}

.boxspabottunspacece {
  padding: 20px;
}

.bottunspace {
  margin-bottom: 20px;
}

.boxspace {
  padding: 20px;
}

.placeholders {
  margin-bottom: 30px;
  text-align: center;
}

.placeholders h4 {
  margin-bottom: 0;
  font-size: 36px;
}

.panel-info {
  padding-bottom: 10px;
  /* border: 2px solid #3c3593; */
}

.panel {
  border-radius: 0px;
  margin-bottom: 0px;
  background-color: #fff;
}
.no-gutters {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.panel-body {
  margin-top: 10px;
}

.bar-card {
  border: none;
  border-radius: 5px;
}

.panel-info .panel-heading {
  border-radius: 0px;
  color: #fff;
  /* background-color: #2d4050; */
  background: linear-gradient(to bottom, rgba(255,255,255,0.15) 0%, rgba(0,0,0,0.15) 100%), radial-gradient(at top center, rgba(255,255,255,0.40) 0%, rgb(255 0 0 / 40%) 120%) #d22027;
  background-blend-mode: multiply,multiply;
  /* border-color: #3e3797; */
 font-size: 16px;
    text-align: center;

}

.panel-heading {
  padding: 5px 5px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
  box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
}

.panel-success {
  padding-bottom: 10px;
  /* border: 2px solid #3c3593; */
}

.panel-success > .panel-heading {
  border-radius: 0px;
  color: #fff;
  background-color: #2d4050;
  /* background-image: linear-gradient(to right, #3c3592, #5147c9); */
  border-color: #3e3797;
  font-size: 18px;
}

.panel-warning {
  padding-bottom: 10px;
  /* border: 2px solid #3c3593; */
}

.panel-warning > .panel-heading {
  border-radius: 0px;
  color: #fff;
  background-color: #2d4050;
  /* background-image: linear-gradient(to right, #3c3592, #5147c9); */
  border-color: #3e3797;
  font-size: 18px;
}

.panel-danger {
  padding-bottom: 10px;
  /* border: 2px solid #3c3593; */
}

.panel-danger > .panel-heading {
  border-radius: 0px;
  color: #fff;
  background-color: #2d4050;
  /* background-image: linear-gradient(to right, #3c3592, #5147c9); */
  border-color: #3e3797;
  font-size: 18px;
}

.panel-primary {
  padding-bottom: 10px;
  /* border: 2px solid #3c3593; */
}

.panel-primary > .panel-heading {
  border-radius: 0px;
  color: #fff;
  background-color: #2d4050;
  /* background-image: linear-gradient(to right, #3c3592, #5147c9); */
  border-color: #3e3797;
  font-size: 18px;
}

.boxx .cards {
  border: none;
  border-radius: 0px;
  box-shadow: none;
  /* box-shadow: 0 2px 10px rgb(0 0 0 / 10%) !important; */
}

.bux {
  /* box-shadow: 0 2px 10px rgb(0 0 0 / 10%) !important;
  background: #fff; */
  justify-content: space-around;
}

.bux .btn {
  padding: 0.2rem 0.1rem !important;
}
.col-6.col-sm-2.service-card {
  border-right: 2px solid rgba(0,0,0,0.15) ;
}
.margin-y {
  margin: 1rem 0 !important;
}
.fa-4x {
  border-radius: 50%;
  font-size: 1.8rem !important;
  color: #E50013;
}
.display-4 {
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.2;
  text-align: left;
}

.text-uppercase {
  text-transform: uppercase !important;
  text-align: left;
}

.service-card {
  text-align: center;
  /* box-shadow: 0 2px 2px rgb(0 0 0 / 20%); */
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 0.1);
}

.service-card:hover {
  box-shadow: 4px 4px 4px rgb(58 105 243 / 30%);
  transform: translate(0, -6px);
  box-shadow: 0 0px 0px rgb(0 0 0 / 20%);
}

.m-top {
  margin: 2rem 0;
}

/* responsiveness */
@media screen and (min-width: 679px) and (max-width: 1320px) {
  .btn-success {
    margin: 0 0px !important;
    padding: -10px !important;
    font-size: 12px !important;
  }

  .btn-primary {
    margin: 0 0px !important;
    padding: -10px !important;
    font-size: 12px !important;
  }
}

.cross-button {
  display: grid;
  align-items: center;
  background: #f2f2f2;
  margin: 11px 0 0 0;
  padding: 3px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

@media (max-width: 768px) {
  .boxspace {
    padding: 0 20px;
}
.bottunspace {
  margin-bottom: 0px;
}
  .me-4 {
    margin-right: 10rem!important;
}
.form-wraper {
  position: relative;
  padding: 10px 2rem 0 0;
}
.flex-grow-1.me-2 {
  text-align: center;}
  .mt5 {
    margin-top: 0rem;
  }
  img.img-fluid.liginbg {
    height: 200px;
}
.container-fluid.loginbg {
  padding-left: 0px;
  padding-right: 0px;
}
  .dash-card .fa {
    padding: 7px;
    font-size: 15px;
    width: 55px !important;}
    .col-md-4.card-d {
      border-bottom: 2px solid #f5b4b9;
  }
  .cross-button {
    margin: 11px 0 0 15px;
  }

  .panel-heading {
    padding: 10px 8px;
  }

  .panel {
    margin: 10px;
  }

  .m-top {
    margin: 0rem 0 4rem 0;
  }

  .m-button {
    margin-bottom: -2rem 0 5rem;
  }

  
}

.user-area:hover .user-menu {
  display: inline-block;
}

@media (max-width: 768px) {
  .user-area:hover .user-menu {
    display: block;
  }
}


.display-4 {
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.2;
  text-align: left;
}

.text-uppercase {
  text-transform: uppercase !important;
  text-align: left;
}

.service-card {
  text-align: center;
  /* box-shadow: 0 2px 2px rgb(0 0 0 / 20%); */
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 0.1);
}

.service-card:hover {
  box-shadow: 4px 4px 4px rgb(58 105 243 / 30%);
  transform: translate(0, -6px);
  box-shadow: 0 0px 0px rgb(0 0 0 / 20%);
}

.m-top {
  margin: 0 0 5rem 0;
}
 
/* dash-style */
.dash li::after {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  right: -1.5em;
  width: 0;
  height: 0;
  border-top: 2.5em solid #EEE;
  border-right: 1.563em solid transparent;
}
.dash li::before {
  content: '';
  position: absolute;
  display: block;
  left: -1.5em;
  width: 0;
  height: 0;
  border-top: 2.5em solid transparent;
  border-right: 1.563em solid #EEE;
}
.headerbg{
  background-color: #fafafa;
  margin-top: 25px;}
.dash .card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: .5rem !important;
}
.dash .card-header {
  padding: 0.9rem !important;
  margin-bottom: 0;
  /* background-color: #fdf2e8; */
  border-bottom: 1px solid rgba(0,0,0,.125);
}
.big-box{
  background: #fbfbfd;
  padding: 10px;
  border-radius: 10px;
  /* margin: 0 5px; */
  box-shadow: -1px 4px 28px 0px rgb(0 0 0 / 8%);
}
.small-box
 {background: #fbfbfd;
  padding: 10px;
  border-radius: 10px;
  box-shadow: -1px 4px 28px 0px rgb(0 0 0 / 8%);
}
.box-data{text-align: center;
  padding: 0px 0 0 0;
  border: 1px solid #c8c8c8;
  border-radius: 10px;
  background: #fff;
  margin: px 0;}
.count-no{
  margin: 10px 0 0 0 !important;
  font-size: 2rem;
}
.text-head{    
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  color: #000;}
  .text-head1 {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    color: #E50013;
    background: #fdf2e8;
}
  .Target-root.css-4dretl {
    height: 400px !important;
  
}

.css-legend span {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
    text-transform: lowercase;
}

/* responsiveness */
@media screen and (min-width: 679px) and (max-width: 1320px) {
  .btn-success {
    margin: 0 0px !important;
    padding: -10px !important;
    font-size: 12px !important;
  }

  .btn-primary {
    margin: 0 0px !important;
    padding: -10px !important;
    font-size: 12px !important;
  }
}

.cross-button {
  display: grid;
  align-items: center;
  background: #f2f2f2;
  margin: 11px 0 0 0;
  padding: 3px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

@media (max-width: 768px) {
  .Target-root.css-4dretl {
    height: 330px !important;
  
}
  .cross-button {
    margin: 11px 0 0 15px;
  }

  .panel-heading {
    padding: 10px 8px;
  }
  .panel-info .panel-heading {
    border-radius: 0px;
    color: #fff;
    /* background-color: #2d4050; */
    /* background: linear-gradient(to bottom, rgba(255,255,255,0.15) 0%, rgba(0,0,0,0.15) 100%), radial-gradient(at top center, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.40) 120%) #989898;
    background-blend-mode: multiply,multiply;
    border-color: #3e3797; */
    font-size: 15px;
}

  .panel {
    margin: 10px;
  }

  .m-top {
    margin: -2rem 0 5rem 0;
  }

  .m-button {
    margin-bottom: -2rem 0 5rem;
  }

  .service-card {
    text-align: center;
    /* box-shadow: 0 2px 2px rgb(0 0 0 / 20%); */
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 0.1);
  }

  .p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link
    .p-menuitem-text,
  .p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link
    .p-menuitem-icon,
  .p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link
    .p-submenu-icon {
    color: #fff !important;
    font-size: 14px;
  }

  p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link:not(.p-disabled):hover {
    background: #01454a;
  }
}

.user-area:hover .user-menu {
  display: inline-block;
}

@media (max-width: 768px) {
  .user-area:hover .user-menu {
    display: block;
  }
  .holos img {
    width: 125px !important;
}
}
.symbol.symbol-40px .symbol-label {
  width: 50px;
  height: 50px;
}
span.text-muted.fw-semibold.d-block.fs-7 {
  font-size: 1.8rem;
}
span.text-gray-800.text-hover-primary.fs-6.fw-bold {
  font-size: 1rem;
}
.symbol .symbol-label {
  width: 50px;
  height: 50px;
}
.symbol .symbol-label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: var(--bs-symbol-label-color);
  background-color: var(--bs-symbol-label-bg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 0.475rem;
}
.symbol {
  display: inline-block;
  flex-shrink: 0;
  position: relative;
  border-radius: 0.475rem;
}
.me-4 {
  margin-right: 1rem;
}

div {
  display: block;
}
.separator {
  display: block;
  height: 0;
  border-bottom: 1px solid #dbdfe9;
}
.text-icon {
  color: #fff !important;
}