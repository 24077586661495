.W_card {
  display: flex !important;
  justify-content: center !important;
  padding: 10px !important;
  text-align: center !important;
  color: #fff;
}
.W_card_row {
  background: #d40c17;
}
.p_name_col {
  background: #b5b5b5;
  color: fff;
  color: #000;
  padding: 5px;

  display: flex;
  justify-content: center;
}
.p_name_col p {
  font-size: 1.2rem;
  font-weight: 600;
}
.wa_table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.wa_table td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.social_row_wa {
  display: flex;
  justify-content: center;
  text-align: center;
}
._waproductimg {
  width: 250px;
}
/* .datatable-filter-demo {
  margin: 0px !important;
} */
.wa_AuthPageTopbox {
  background-color: #e50013;
  height: 74px;
}
@media (max-width: 600px) {
  ._waproductimg {
    width: 190px;
  }
  .wa_AuthPageTopbox {
    height: 104px;
  }
}
